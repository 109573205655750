import api from "@api";

// VOID return body. Data in the header
function doRegister({
  email,
  senha,
  name,
  cpf = "",
  telefone = "",
  imagem = null,
  userIdApple = null,
  plataforma = "website",
  setCurrentProgress,
}) {
  if (!!email && !!senha && !!name) {
    const payload = {
      pathWithDomain: `${process.env.REACT_APP_API_V2}/v3/clientes/register_user`,
      method: "post",
      body: {
        email,
        password: senha,
        nome: name,
        cpf,
        telefone,
        imagem,
        userIdApple,
        plataforma,
      },
    };

    return api(payload, setCurrentProgress);
  }
  return false;
}

export default doRegister;
