import api from "@api";

// import {mockAPIRequest} from "@helpers";

/*
@TODO: typescript
{"id":1,"nome":"Básico","qtdConsultas":1,"qtdDownloads":5,"qtdTermos":1,"qtdEnvioEmail":1,"referenciaIugu":"basic_plan","identifierIugu":null}
*/
function getPlano() {
  const payload = {
    pathWithDomain: `${process.env.REACT_APP_API_V2}/v3/clientes/my-plan`,
    method: "get",
  };

  // @TODO: remove
  // return mockAPIRequest({
  //   timeToWait: 1000,
  //   whatToReturn: {
  //     data: {
  //       id: 1,
  //       nome: "Basic",
  //       qtdConsultas: 5,
  //       qtdDownloads: 10,
  //       qtdTermos: 5,
  //       qtdEnvioEmail: 5,
  //       referenciaIugu: "subscription_classic",
  //       identifierIugu: "basic_month_homolog_adoo",
  //     },
  //   },
  //   shouldThrowError: false,
  // });

  return api(payload);
}

export default getPlano;
