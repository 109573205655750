import api from "@api";
import {log} from "@helpers";

const createMensagemSuporte = async (
  mensagem,
  assunto = "Mensagem enviada pelo portal"
) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  if (!currentUser) return;
  const payload = {
    pathWithDomain: `${process.env.REACT_APP_API_V2}/suporte/insert`,
    method: "post",
    body: {
      assunto,
      mensagem,
    },
  };
  log("Enviar mensagem de suporte payload:", payload);

  return api(payload);
};

export default createMensagemSuporte;
