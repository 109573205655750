import api from "@api";

import {log} from "@helpers";

const sendEmailDiarioOficial = (data, setCurrentProgress) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  if (!currentUser) return;
  const payload = {
    pathWithDomain: `${process.env.REACT_APP_API_V2}/jornal/sendemail-site?cliente=${currentUser.user.idCliente}&diario=${data.id}&data=${data.data}`,
    method: "post",
  };
  log("Enviado e-mail de diário payload:", payload);

  return api(payload, setCurrentProgress);
};

export default sendEmailDiarioOficial;
