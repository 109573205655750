/* eslint-disable no-console */
import createMensagemSuporte from "@api/requests/suporte/createMensagemSuporte";
export const log = (description, value, config) => {
  if (
    process.env.REACT_APP_OUTPUT_CONSOLE_LOG &&
    process.env.REACT_APP_OUTPUT_CONSOLE_LOG === "true"
  ) {
    console.log(description, value);
  }

  if (config && config.sendToSupport && value) {
    try {
      const asString = JSON.stringify(value);
      if (asString) {
        createMensagemSuporte(asString, "WEBAPP LOG() ERROR");
      }
    } catch (error) {}
  }
};
