import React, {createContext, useState, useContext, useEffect} from "react";
import {useLoadingBar} from "@context/LoadingBar";
import {log} from "@helpers";

import getBuscaTermoResultados from "@api/requests/buscaTermoResultados/getBuscaTermoResultados";
import {usePlanoAlertaModal} from "@context/PlanoAlertaModal";

import {toast} from "react-toastify";

const BuscaTermoContext = createContext();

export default function BuscaTermoProvider({children}) {
  const {setCurrentProgress} = useLoadingBar();
  const {openAlert} = usePlanoAlertaModal();
  const [buscaTermoResultados, setBuscaTermoResultados] = useState([]);
  // const [isFirtLoaded, setIsFirtLoaded] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    reloadBuscaTermoResultados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const reloadBuscaTermoResultados = async (filtro, type, pagina = 1) => {
    log("Reload API buscaTermoResultados", filtro);
    const result = buscaTermoResultados.findIndex(
      (resultado) => resultado?.id === filtro?.diariosSelecionados[0].id
    );
    if (result !== -1) {
      return;
    }
    // API call buscaTermoResultados
    if (
      filtro &&
      filtro.searchString &&
      filtro.diariosSelecionados &&
      filtro.dataInicio &&
      filtro.dataFim
    ) {
      try {
        // API call buscaTermoResultados
        setIsloading(true);

        const resultado = await getBuscaTermoResultados(
          filtro,
          type,
          pagina,
          setCurrentProgress
        );
        setIsloading(false);
        setBuscaTermoResultados((prev) => [...prev, ...resultado]);
      } catch (error) {
        setIsloading(false);
        if (error.message) {
          let type = error.message?.replaceAll("Error: ", "");
          openAlert({
            type,
            goBackOnClose: true,
          });
        } else {
          toast.error(error, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
        }
      }
    }
  };

  return (
    <BuscaTermoContext.Provider
      value={{
        buscaTermoResultados,
        setBuscaTermoResultados,
        reloadBuscaTermoResultados,
        isLoading,
      }}
    >
      {children}
    </BuscaTermoContext.Provider>
  );
}

export function useBuscaTermo() {
  const context = useContext(BuscaTermoContext);
  const {
    buscaTermoResultados,
    setBuscaTermoResultados,
    reloadBuscaTermoResultados,
    isLoading,
  } = context;

  return {
    buscaTermoResultados,
    setBuscaTermoResultados,
    reloadBuscaTermoResultados,
    isLoading,
  };
}
