import api from "@api";

import {log} from "@helpers";
import {HasAppSubscriptionAPI} from "@contracts";

const hasAppSubscription = async (
  setCurrentProgress: undefined
): Promise<HasAppSubscriptionAPI | void> => {
  const currentUser: string = JSON.parse(localStorage.getItem("currentUser") || "");
  if (!currentUser) return;
  const payload = {
    pathWithDomain: `${process.env.REACT_APP_API_V2}/v3/clientes/hasAppSubscription`,
    method: "GET",
  };
  log("GET hasAppSubscription:", payload);

  // @TODO: remove
  // return mockAPIRequest({
  //   timeToWait: 1000,
  //   whatToReturn: {data: {hasSub: true}},
  //   shouldThrowError: false,
  // });
  return api(payload, setCurrentProgress);
};

export default hasAppSubscription;
