import React, {createContext, useState, useContext} from "react";

const LoadingBarContext = createContext();

export default function LoadingBarProvider({children}) {
  const [currentProgress, setCurrentProgress] = useState(0);

  return (
    <LoadingBarContext.Provider
      value={{
        currentProgress,
        setCurrentProgress,
      }}
    >
      {children}
    </LoadingBarContext.Provider>
  );
}

export function useLoadingBar() {
  const context = useContext(LoadingBarContext);
  const {currentProgress, setCurrentProgress} = context;

  return {
    currentProgress,
    setCurrentProgress,
  };
}
