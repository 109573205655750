import api from "@api";

import {log} from "@helpers";
import {CurrentUserObjectType, DiarioType} from "@types";

interface CreateAlerta {
  alerta: any;
  diariosSelecionados: DiarioType[];
  setCurrentProgress?: Function;
}

const updateAlerta = ({
  alerta,
  diariosSelecionados,
  setCurrentProgress,
}: CreateAlerta) => {
  const currentUser: CurrentUserObjectType = JSON.parse(
    localStorage.getItem("currentUser") as string
  );
  if (!currentUser) return;
  const payload = {
    pathWithDomain: `${process.env.REACT_APP_API_V2}/termo?clienteId=${currentUser.user.idCliente}`,
    method: "put",
    body: {
      id: alerta.id,
      termo: alerta.termo,
      diarios: diariosSelecionados.map((d: any) => ({id: d.id})),
      tipoBuscaAlias: "busca_exata",
      ativo: true,
    },
  };

  log("Criar alerta de termo payload:", payload);

  return api(payload, setCurrentProgress);
};

export default updateAlerta;
