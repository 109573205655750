import api from "@api";
// import {buildUrlParams} from "@helpers";

async function getCadernos(filtro, diarioSelecionado, pagina, setCurrentProgress) {
  if (diarioSelecionado && diarioSelecionado.id) {
    const payload = {
      pathWithDomain: `${process.env.REACT_APP_API_V2}/jornal/diarios/${diarioSelecionado.id}?page=${pagina}`,
      method: "get",
    };

    const {data: cadernos} = await api(payload, setCurrentProgress);

    return cadernos;
  }
  return false;
}

export function agruparCadernos(cadernos) {
  const cadernosAgrupados = [];
  if (cadernos && cadernos.length > 0) {
    cadernos.forEach((caderno) => {
      const cadernoExiste = cadernosAgrupados.find((c) => c.data === caderno.data);
      if (!cadernoExiste) {
        cadernosAgrupados.push({
          data: caderno.data,
          descricao: caderno.descricao,
          secoes: [
            {
              data: caderno.data,
              codigo: caderno.codigo,
              id: caderno.id,
              link: caderno.link,
              nome: caderno.nome,
              paginas: caderno.paginas,
            },
          ],
        });
      } else {
        const cadernoToUpdateIndex = cadernosAgrupados.findIndex((c) => {
          return c.data === caderno.data;
        });

        const secaoToAdd = {
          data: caderno.data,
          codigo: caderno.codigo,
          id: caderno.id,
          link: caderno.link,
          nome: caderno.nome,
          paginas: caderno.paginas,
        };

        const secoes = [...cadernosAgrupados[cadernoToUpdateIndex].secoes, secaoToAdd];

        cadernosAgrupados[cadernoToUpdateIndex] = {
          ...cadernosAgrupados[cadernoToUpdateIndex],
          secoes,
        };
      }
    });
  }

  return cadernosAgrupados;
}

export default getCadernos;
