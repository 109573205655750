import validarCupom from "@api/requests/cupom/validarCupom";
import {
  BASICO_ANUAL,
  BASICO_MENSAL,
  PREMIUM_ANUAL,
  PREMIUM_MENSAL,
} from "@constants/planos";
import {createContext, useContext, useState} from "react";

const validarCupomContext = createContext();
export default function ValidarCupomProvider({children}) {
  const [cupomData, setCupomData] = useState(null);
  const [cupomErrorMessage, setCupomErrorMessage] = useState("");
  const [isValidCupom, setIsValidCupom] = useState(false);
  const [planoSelecionadoData, setPlanoSelecionadoData] = useState();
  const [codigoCupomValue, setCodigoCupomValue] = useState("");
  const [cupomRequestIsLoading, setCupomRequestIsLoading] = useState(false);

  const validarCupomHandler = async (codigoCupomValue, planoSelecionado) => {
    setPlanoSelecionadoData(planoSelecionado);
    if (codigoCupomValue !== "") {
      setCupomRequestIsLoading(true);
      await validarCupom({
        codCupom: codigoCupomValue,
        planoId: planoSelecionado.idPlano,
      })
        .then((response) => [
          setCupomData(response.data),
          setIsValidCupom(true),
          setCupomRequestIsLoading(false),
        ])
        .catch((err) => [setCupomErrorMessage(err), setCupomRequestIsLoading(false)]);
    }
  };

  const getCupomValorDesconto = () => {
    if (
      planoSelecionadoData?.idPlano === BASICO_MENSAL.id ||
      planoSelecionadoData?.idPlano === PREMIUM_MENSAL.id
    ) {
      return parseFloat(
        planoSelecionadoData?.precoPlanoMensal.replace(",", ".") - getCupomValorPlano()
      ).toFixed(2);
    } else if (
      planoSelecionadoData?.idPlano === BASICO_ANUAL.id ||
      planoSelecionadoData?.idPlano === PREMIUM_ANUAL.id
    ) {
      return parseFloat(
        planoSelecionadoData?.precoPlanoAnual.replace(",", ".") - getCupomValorPlano()
      ).toFixed(2);
    }
  };

  const getCupomValorPlano = () => {
    return cupomData?.valorPlano.toFixed(2);
  };

  const resetCupom = () => {
    setCupomData(null);
    setIsValidCupom(false);
    setCodigoCupomValue("");
    setCupomErrorMessage(null);
  };

  return (
    <validarCupomContext.Provider
      value={{
        validarCupomHandler,
        getCupomValorDesconto,
        cupomErrorMessage,
        isValidCupom,
        getCupomValorPlano,
        resetCupom,
        codigoCupomValue,
        setCodigoCupomValue,
        cupomRequestIsLoading,
        setCupomErrorMessage,
      }}
    >
      {children}
    </validarCupomContext.Provider>
  );
}

export function useValidarCupom() {
  const context = useContext(validarCupomContext);
  const {
    validarCupomHandler,
    cupomErrorMessage,
    isValidCupom,
    getCupomValorPlano,
    resetCupom,
    getCupomValorDesconto,
    codigoCupomValue,
    setCodigoCupomValue,
    cupomRequestIsLoading,
    setCupomErrorMessage,
  } = context;

  return {
    validarCupomHandler,
    cupomErrorMessage,
    isValidCupom,
    getCupomValorPlano,
    resetCupom,
    getCupomValorDesconto,
    codigoCupomValue,
    setCodigoCupomValue,
    cupomRequestIsLoading,
    setCupomErrorMessage,
  };
}
