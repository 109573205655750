import React, {createContext, useState, useContext} from "react";

const PlanoAlertaModalContext = createContext();

export const TYPES_ERROS_PLANO = {
  busca: {
    titulo: "Você atingiu sua cota de busca de termos",
    subtitulo:
      "Faça upgrade do seu plano para poder fazer mais buscas nos Diários Oficiais!",
  },
  email: {
    titulo: "Você atingiu sua cota de envio de e-mails",
    subtitulo:
      "Faça upgrade do seu plano para poder enviar mais Diários Oficiais para o seu e-mail!",
  },
  alerta: {
    titulo: "Você atingiu sua cota de criação de alertas",
    subtitulo: "Faça upgrade do seu plano para poder ativar ou criar mais alertas!",
  },
  download: {
    titulo: "Você atingiu sua cota de downloads",
    subtitulo:
      "Faça upgrade do seu plano para poder fazer mais downloads de Diários Oficiais!",
  },
};

export default function PlanoAlertaModalProvider({children}) {
  const [isVisible, setIsVisible] = useState(false);
  const [messageValue, setMessageValue] = useState("");
  const [type, setType] = useState(null);
  const [goBackOnCloseVal, setGoBackOnCloseVal] = useState(true);
  const [title, setTitle] = useState("");

  //TODO: Retirar message
  const openAlert = ({type, message, goBackOnClose = true}) => {
    if (type in TYPES_ERROS_PLANO) {
      setIsVisible(true);
      // Remove "Error: " string from message.P
      //setMessageValue(message.replace("Error: ", ""));
      setTitle(TYPES_ERROS_PLANO[type].titulo);
      setMessageValue(TYPES_ERROS_PLANO[type].subtitulo);
      setType(type);
      setGoBackOnCloseVal(goBackOnClose);
    }
  };

  const closeAlert = () => {
    setIsVisible(false);
    setMessageValue("");
    setType(null);
  };

  const getMessage = () => {
    return {
      message: messageValue,
      type,
      title,
    };
  };

  return (
    <PlanoAlertaModalContext.Provider
      value={{
        isVisible,
        getMessage,
        openAlert,
        closeAlert,
        goBackOnClose: goBackOnCloseVal,
      }}
    >
      {children}
    </PlanoAlertaModalContext.Provider>
  );
}

export function usePlanoAlertaModal() {
  const context = useContext(PlanoAlertaModalContext);
  const {
    isVisible,
    getMessage,
    openAlert,
    closeAlert,
    goBackOnClose: goBackOnCloseVal,
  } = context;

  return {isVisible, getMessage, openAlert, closeAlert, goBackOnClose: goBackOnCloseVal};
}
