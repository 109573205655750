import api, {authHeader} from "../../api";

/*
{"idCliente":9998,"nome":"mateusgff+real11","email":"mateusgff+real11@gmail.com","token":null,"plano":{"id":1,"nome":"Básico","preco":null,"precoComDesconto":null,"dataExpiracaoOferta":null,"referenciaIugu":"basic_plan","listaBeneficios":[{"id":1,"descricao":"Criação de 1 alerta de palavra-chave válido por 15 dias","descricaoHtml":null,"permitido":true},{"id":2,"descricao":"Download de até 5 jornais oficiais por dia","descricaoHtml":null,"permitido":true},{"id":3,"descricao":"Notificações de alertas por e-mail","descricaoHtml":null,"permitido":true},{"id":10,"descricao":"Consulta de 1 termo em qualquer diário oficial disponível","descricaoHtml":null,"permitido":true},{"id":22,"descricao":"Envio de 1 diário oficial por email diariamente","descricaoHtml":null,"permitido":true},{"id":23,"descricao":"Atendimento de suporte prioritário","descricaoHtml":null,"permitido":false}],"qtdDownloads":5,"qtdConsultas":1,"qtdTermos":1,"qtdEnvioEmail":1,"notificacaoPlano":[{"id":1,"nome":"E-mail"}],"qtdDiasGratisAssinatura":null,"corHexadecimal":"#cccccc","referenciaAndroid":"basic_plan","recorrenciaPagamento":"mes"},"telefone":"","linkImagem":null,"listaFormaNotificacao":[{"id":1,"nome":"E-mail"},{"id":2,"nome":"Push"}],"planoTesteGratuito":null}
*/
function getLoggedInUserData(accessToken, setCurrentProgress) {
  const inputAccessToken = accessToken ? {Authorization: `${accessToken}`} : authHeader();
  const payload = {
    pathWithDomain: `${process.env.REACT_APP_API_V2}/v3/clientes/findUser`,
    method: "get",
    headers: inputAccessToken,
  };
  return api(payload, setCurrentProgress);
}

export default getLoggedInUserData;
