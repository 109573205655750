import api from "@api";

// VOID return
function doLogin({email, senha, setCurrentProgress}) {
  if (!!email && !!senha) {
    const payload = {
      pathWithDomain: `${process.env.REACT_APP_API_V2}/login`,
      method: "post",
      body: {email, senha},
    };
    return api(payload, setCurrentProgress);
  }
  return false;
}

export default doLogin;
