import api from "@api";

function doLoginSocialMedia({token, email, nome, sso, setCurrentProgress}) {
  if (!!token && !!email && !!nome && !!sso) {
    const payload = {
      pathWithDomain: `${process.env.REACT_APP_API_V2}/v3/clientes/public/validate-oauth`,
      method: "put",
      body: {token, email, nome, sso},
    };
    return api(payload, setCurrentProgress);
  }
  return false;
}

export default doLoginSocialMedia;
