import api from "@api";
// import {buildUrlParams} from "@helpers";

async function getCadernoByData(filtro, diarioSelecionado, pagina, setCurrentProgress) {
  const handleFormatDate = (date) => {
    const dateFormatted = new Date(date);
    const day = dateFormatted.getDate().toString().padStart(2, "0");
    const month = (dateFormatted.getMonth() + 1).toString().padStart(2, "0");
    const year = dateFormatted.getFullYear();
    return `${day}/${month}/${year}`;
  };
  if (diarioSelecionado && diarioSelecionado.id) {
    const dataInicio = handleFormatDate(
      filtro.dataInicio ? filtro.dataInicio : new Date()
    );
    const dataFim = handleFormatDate(filtro.dataFim ? filtro.dataFim : new Date());

    const payload = {
      pathWithDomain: `${process.env.REACT_APP_API_V2}/jornal/by_interval/diarios/${diarioSelecionado.id}?page=${pagina}&dataInicial=${dataInicio}&dataFinal=${dataFim}`,
      method: "get",
    };

    const {data: cadernos} = await api(payload, setCurrentProgress);

    return cadernos;
  }
  return false;
}

export default getCadernoByData;
