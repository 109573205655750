import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Alert from "react-bootstrap/Alert";
import LoginForm from "@adoo-ui/Organism/LoginForm/LoginForm";
import LoginModalSidePanel from "@adoo-ui/Molecule/LoginModalSidePanel";
import CadastroForm from "@adoo-ui/Organism/LoginForm/CadastroForm";
import "./LoginModal.scss";
import EsqueciSenhaForm from "@adoo-ui/Organism/LoginForm/EsqueciSenhaForm";
import * as analytics from "@helpers/ga4";

function LoginModal({
  login,
  register,
  loginError,
  registerError,
  socialMediaLogin,
  registerWithSocial,
  onHide,
  onSuccessLogin,
  typeRightPanel = "default",
  ...rest
}) {
  const [defaultActiveKey, setDefaultActiveKey] = useState("login");
  const [showEsqueciMinhaSenha, setShowEsqueciMinhaSenha] = useState(false);
  const [showMsgEmailValidacao, setShowMsgEmailValidacao] = useState(false);

  const submitLogin = async ({email, senha}) => {
    analytics.actionTrack("Login", false);
    const res = await login({email, senha});
    if (res) {
      onSuccessLogin();
    }
  };

  const submitRegister = async ({email, senha, name}) => {
    analytics.actionTrack("Cadastro", false);
    const res = await register({email, senha, name});
    if (res) {
      setShowMsgEmailValidacao(true);
    }
  };

  const comp = () => {
    switch (typeRightPanel) {
      case "navegacao":
        return (
          <LoginModalSidePanel ordemItens={["navegacao", "buscar-termo", "alertas"]} />
        );
      case "buscar-termo":
        return (
          <LoginModalSidePanel ordemItens={["buscar-termo", "navegacao", "alertas"]} />
        );
      case "alertas":
        return (
          <LoginModalSidePanel ordemItens={["alertas", "buscar-termo", "navegacao"]} />
        );
      default:
        return (
          <LoginModalSidePanel ordemItens={["navegacao", "alertas", "buscar-termo"]} />
        );
    }
  };

  return (
    <Modal
      {...rest}
      onHide={() => {
        onHide({action: "go-back"});
      }}
      backdrop="static"
      keyboard={true}
      dialogClassName="modal-login-width"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton style={{zIndex: 2}}></Modal.Header>
      <Modal.Body>
        <div className="login-modal-panels-wrapper">
          <div className="login-modal-left-panel">
            <div className="login-modal">
              {!showEsqueciMinhaSenha ? (
                <Tabs
                  activeKey={defaultActiveKey}
                  id="uncontrolled-tab-example"
                  className="mb-3"
                  onSelect={(k) => setDefaultActiveKey(k)}
                >
                  <Tab eventKey="login" title="ENTRAR">
                    {loginError ? <Alert variant="danger">{loginError}</Alert> : null}
                    <LoginForm
                      submitLogin={submitLogin}
                      loginWithSocial={(provider) => socialMediaLogin(provider)}
                      handleAbrirEsqueciMinhaSenha={() => setShowEsqueciMinhaSenha(true)}
                      showCadastro={() => setDefaultActiveKey("cadastro")}
                    />
                  </Tab>
                  <Tab eventKey="cadastro" title="CADASTRAR-SE">
                    {registerError ? (
                      <Alert variant="danger">{registerError}</Alert>
                    ) : null}
                    <CadastroForm
                      submitRegister={submitRegister}
                      registerWithSocial={registerWithSocial}
                      showMsgEmailValidacao={showMsgEmailValidacao}
                      handleFecharEmailValidacao={() => setShowMsgEmailValidacao(false)}
                    />
                  </Tab>
                </Tabs>
              ) : (
                <EsqueciSenhaForm
                  onFecharEsqueciMinhaSenha={() => setShowEsqueciMinhaSenha(false)}
                />
              )}
            </div>
          </div>
          <div className="login-modal-right-panel large-only">{comp()}</div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default LoginModal;
