import api from "@api";

import {log} from "@helpers";

const createAlerta = (data, setCurrentProgress) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  if (!currentUser) return;
  const payload = {
    pathWithDomain: `${process.env.REACT_APP_API_V2}/termo?clienteId=${currentUser.user.idCliente}`,
    method: "post",
    body: {
      termo: data.termo,
      diarios: data.diarios.map((d) => ({id: d.id})),
      tipoBuscaAlias: "busca_exata",
      ativo: true,
    },
  };
  log("Criar alerta de termo payload:", payload);

  return api(payload, setCurrentProgress);
};

export default createAlerta;
