import "./App.scss";
import {useAnalytics} from "@hooks/useAnalytics";

import DiariosOficiaisProvider from "../../context/DiariosOficiais";
import DiarioOficialFiltroProvider from "../../context/DiarioOficialFiltro";
import CadernoProvider from "../../context/Caderno";
import BuscaTermoFiltroProvider from "../../context/BuscaTermoFiltro";
import BuscaTermoProvider from "../../context/BuscaTermo";
import AlertaFiltroProvider from "../../context/AlertaFiltro";
import AlertaProvider from "../../context/Alerta";
import AuthProvider from "../../context/Auth";
import PlanoAlertaModalProvider from "../../context/PlanoAlertaModal";
import PagamentoProvider from "../../context/Pagamento";
import LoadingBarProvider from "../../context/LoadingBar";
import ValidarCupomProvider from "../../context/ValidarCupom";
import AllDiariosFiltroProvider from "../../context/AllDiariosFiltro";

function App(props) {
  // Must be called inside <Router> since it makes use of useLocation()
  useAnalytics();

  // @TODO: split into different domains. (E.g Navegacao, BuscarTermo, Alerta). Each domain will load only its necessary contexts
  return (
    <LoadingBarProvider>
      <AuthProvider>
        <PlanoAlertaModalProvider>
          <DiariosOficiaisProvider>
            <DiarioOficialFiltroProvider>
              <CadernoProvider>
                <BuscaTermoFiltroProvider>
                  <BuscaTermoProvider>
                    <AlertaFiltroProvider>
                      <AlertaProvider>
                        <AllDiariosFiltroProvider>
                          <ValidarCupomProvider>
                            <PagamentoProvider>
                              <div className="App">{props.children}</div>
                            </PagamentoProvider>
                          </ValidarCupomProvider>
                        </AllDiariosFiltroProvider>
                      </AlertaProvider>
                    </AlertaFiltroProvider>
                  </BuscaTermoProvider>
                </BuscaTermoFiltroProvider>
              </CadernoProvider>
            </DiarioOficialFiltroProvider>
          </DiariosOficiaisProvider>
        </PlanoAlertaModalProvider>
      </AuthProvider>
    </LoadingBarProvider>
  );
}
export default App;
