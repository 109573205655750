import api from "@api";

const getAllDiarios = (filtro, setCurrentProgress) => {
  const payload = {
    pathWithDomain: `${process.env.REACT_APP_API_V2}/diario/findAllUserLogado?poder=${filtro.poder}&esfera=${filtro.esfera}`,
    method: "get",
  };

  return api(payload, setCurrentProgress);
};

export default getAllDiarios;
