import React, {ButtonHTMLAttributes} from "react";
import "./Button.scss";
import {MouseEvent} from "react";

interface IAdooButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  id?: string;
  children?: React.ReactNode;
  onClick?: (event: MouseEvent) => void;
}

const Button: React.FC<IAdooButtonProps> = (props) => {
  return (
    <button {...props} className={`adoo-button ${props.className}`}>
      {props.children}
    </button>
  );
};

export default Button;
