import React, {createContext, useState, useContext, useEffect} from "react";
import {useLoadingBar} from "@context/LoadingBar";
import getAlertas from "@api/requests/alertas/getAlertas";
import getHistoricoDeAlertas from "@api/requests/alertas/getHistoricoDeAlertas";
import getHistoricoDeAlertasDetalhes from "@api/requests/alertas/getHistoricoDeAlertasDetalhes";

import {log} from "@helpers";

const AlertaContext = createContext();

export default function AlertaProvider({children}) {
  const {setCurrentProgress} = useLoadingBar();
  const [alertasCadastrados, setAlertasCadastrados] = useState([]);
  const [historico, setHistorico] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    reloadAlertasCadastrados();
  }, []);

  const loadHistorico = ({mes, ano}) => {
    reloadHistorico({mes, ano});
  };

  const reloadAlertasCadastrados = async () => {
    log("Reload API alertas cadastrados", {});
    // API call Alertas Cadastrados
    try {
      setIsLoading(true);
      const res = await getAlertas(setCurrentProgress);
      if (!res) return;
      log("Resultado Reload API alertas cadastrados", {alertas: res.data.listaTermos});
      setAlertasCadastrados(res.data.listaTermos);
    } catch (error) {
      log("Erro Reload API alertas cadastrados", {error});
    } finally {
      setIsLoading(false);
    }
  };

  const reloadHistorico = async ({mes, ano}) => {
    log("Reload API historico de alertas", {});
    // API call Alertas Cadastrados
    const res = await getHistoricoDeAlertas({mes, ano});
    if (!res) return;
    log("Resultado Reload API historico de alertas", {historico: res.data.datas});
    setHistorico(res.data.datas);
  };

  const loadHistoricoDetalhes = async ({diario, termo, dataInicio, dataFim}) => {
    log("Reload API historico de alertas detalhes", {});
    const res = await getHistoricoDeAlertasDetalhes({diario, termo, dataInicio, dataFim});
    if (!res) return;
    log("Resultado Reload API historico de alertas detalhes", res.data);
    return res.data;
  };

  return (
    <AlertaContext.Provider
      value={{
        alertasCadastrados,
        setAlertasCadastrados,
        reloadAlertasCadastrados,
        historico,
        loadHistorico,
        loadHistoricoDetalhes,
        isLoading,
      }}
    >
      {children}
    </AlertaContext.Provider>
  );
}

export function useAlerta() {
  const context = useContext(AlertaContext);
  const {
    alertasCadastrados,
    setAlertasCadastrados,
    reloadAlertasCadastrados,
    historico,
    loadHistorico,
    loadHistoricoDetalhes,
    isLoading,
  } = context;

  return {
    alertasCadastrados,
    setAlertasCadastrados,
    reloadAlertasCadastrados,
    historico,
    loadHistorico,
    loadHistoricoDetalhes,
    isLoading,
  };
}
