import api from "@api";

interface validarCupomProps {
  codCupom: string;
  planoId: number;
}

const validarCupom = ({codCupom, planoId}: validarCupomProps) => {
  const payload = {
    pathWithDomain: `${process.env.REACT_APP_API_V2}/api/v1/cupom/valida-cupom`,
    method: "POST",
    body: {
      plano_id: planoId,
      codigo: codCupom,
    },
  };

  return api(payload);
};

export default validarCupom;
