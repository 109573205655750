import React, {createContext, useState, useContext} from "react";
import moment from "moment";
import {log} from "@helpers";
import {useLoadingBar} from "@context/LoadingBar";

import getCartoesSalvos from "@api/requests/pagamentos/getCartoesSalvos";
import removerCartao from "@api/requests/pagamentos/removerCartao";
import cancelarPlanoAPI from "@api/requests/pagamentos/cancelarPlano";
import updatePlanoAPI from "@api/requests/pagamentos/updatePlano";
import getPlanoExpiraDataAPI from "@api/requests/pagamentos/getPlanoExpiraData";
import getPlanoCanceladoExpiraDataAPI from "@api/requests/pagamentos/getPlanoCanceladoExpiraData";
import setCartaoDefaultAPI from "@api/requests/pagamentos/setCartaoDefault";
import getPlanoAPI from "@api/requests/planos/getPlano";
import getIsPagamentoAprovado from "@api/requests/pagamentos/getIsPagamentoAprovado";
import hasMobileAppSubscriptionAPI from "@api/requests/planos/hasAppSubscription";
import {toast} from "react-toastify";

const PagamentoContext = createContext();

export default function PagamentoProvider({children}) {
  const {setCurrentProgress} = useLoadingBar();
  const [cartoes, setCartoes] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [planoDataExpira, setPlanoDataExpira] = useState(null);
  const [planoCanceladoDataExpira, setPlanoCanceladoDataExpira] = useState(null);
  const [plano, setPlano] = useState(null);
  const [isPlanoCancelado, setIsPlanoCancelado] = useState(false);
  const [pagamentoStatus, setPagamentoStatus] = useState(null);
  const [hasSubscriptionInMobileApp, setHasSubscriptionInMobileApp] = useState(false);

  const fetchPlanoData = async () => {
    setIsloading(true);
    const res = await getPlanoAPI();
    if (res) {
      setPlano(res.data);
      await fetchPlanoCanceladoDataExpira();
      await fetchPlanoDataExpira();
      await hasMobileAppSubscription();
    }
    setIsloading(false);
  };

  const selecionaCartao = async (cartao) => {
    try {
      const novaLista = cartoes.map((c) => {
        if (c.id === cartao.id) {
          return {...c, isDefault: true};
        } else {
          return {...c, isDefault: false};
        }
      });
      setIsloading(true);
      await setCartaoDefaultAPI(cartao);
      setIsloading(false);
      setCartoes(novaLista);
      return true;
    } catch (error) {
      setIsloading(false);
      toast.error("Erro ao salvar cartão princial.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
  };

  const reloadCartoesSalvos = async () => {
    log("Reload API cartoes salvos");
    try {
      setIsloading(true);
      const resultados = await getCartoesSalvos();
      setIsloading(false);
      setCartoes(resultados);
    } catch (error) {
      setIsloading(false);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const fetchPlanoDataExpira = async () => {
    const val = await getPlanoExpiraData();
    log("Plano expira ISO format: ", val);
    setPlanoDataExpira(val);
  };

  const fetchPlanoCanceladoDataExpira = async () => {
    const val = await getPlanoCanceladoExpiraData();
    log("Plano CANCELADO expira ISO format: ", val);
    setPlanoCanceladoDataExpira(val);
  };

  const getPlanoDataExpiraAsString = () => {
    const now = moment();
    const days = moment(planoDataExpira).diff(now, "days");

    return days > 1 ? `${days} dias` : `${days} dia`;
  };

  const getPlanoCanceladoDataExpiraAsString = () => {
    const now = moment();
    const days = moment(planoCanceladoDataExpira).diff(now, "days");

    return days > 1 ? `${days} dias` : `${days} dia`;
  };

  const cancelarPlano = async () => {
    log("cancelar plano API call");
    try {
      setIsloading(true);
      await cancelarPlanoAPI();
      setIsloading(false);
      return true;
    } catch (error) {
      setIsloading(false);
      toast.error("Erro ao cancelar plano.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
  };

  const updatePlano = async (planoId) => {
    log("update plano API call");
    try {
      setIsloading(true);
      await updatePlanoAPI(planoId);
      setIsloading(false);
      return true;
    } catch (error) {
      setIsloading(false);
      toast.error("Erro ao atualizar plano.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
  };

  const getPlanoExpiraData = async () => {
    try {
      setIsloading(true);
      const val = await getPlanoExpiraDataAPI();
      setIsloading(false);

      if (val && val.data.dataExpiracao) {
        return val?.data?.dataExpiracao
          ? moment(val.data.dataExpiracao, "DD/MM/YYYY").toISOString()
          : null;
      }
      return null;
    } catch (error) {
      setIsloading(false);
      toast.error("Erro ao buscar data de expiração do plano.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      return null;
    }
  };

  const getPlanoCanceladoExpiraData = async () => {
    try {
      setIsloading(true);
      const val = await getPlanoCanceladoExpiraDataAPI();
      setIsloading(false);

      if (val && val.data && val.data.dataExpiracao) {
        setIsPlanoCancelado(true);
        return val?.data?.dataExpiracao
          ? moment(val.data.dataExpiracao, "YYYY-MM-DD").toISOString()
          : null;
      } else {
        setIsPlanoCancelado(false);
      }
      return null;
    } catch (error) {
      setIsloading(false);
      setIsPlanoCancelado(false);

      // toast.error("Erro ao buscar data de expiração do plano .", {
      //   position: "top-center",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: false,
      //   draggable: true,
      //   progress: undefined,
      // });
      // eslint-disable-next-line no-console
      console.error(error);
      return null;
    }
  };

  const removerCartaoSalvo = async (id) => {
    try {
      setIsloading(true);
      await removerCartao({id}, setCurrentProgress);
      await reloadCartoesSalvos();
      setIsloading(false);
    } catch (error) {
      setIsloading(false);
      toast.error("Erro ao remover cartão.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  let req;
  const fetchPagamentoWithInterval = () => {
    req = setInterval(async () => {
      fetchPagamentoStatus();
    }, 2000);
  };

  const fetchPagamentoStatus = async () => {
    const val = await getIsPagamentoAprovado();
    log("call fetchPagamentoStatus()", val?.data?.status);
    if (val.data && val.data.status) {
      setPagamentoStatus(val.data.status);

      if (req && val.data.status !== "pending" && val.data.status !== null) {
        log("call clearInterval() on fetchPagamentoStatus", req);
        clearFetchPagamentoWithInterval();
        window.location.reload();
      }

      return val.data.status;
    }
    return null;
  };

  const clearFetchPagamentoWithInterval = () => {
    clearInterval(req);
  };

  const hasMobileAppSubscription = async () => {
    const val = await hasMobileAppSubscriptionAPI();
    setHasSubscriptionInMobileApp(val?.data?.hasSub);
  };

  return (
    <PagamentoContext.Provider
      value={{
        cartoes,
        setCartoes,
        reloadCartoesSalvos,
        isLoading,
        selecionaCartao,
        planoDataExpira,
        getPlanoDataExpiraAsString,
        cancelarPlano,
        updatePlano,
        removerCartaoSalvo,
        fetchPlanoDataExpira,
        fetchPlanoCanceladoDataExpira,
        planoCanceladoDataExpira,
        getPlanoCanceladoDataExpiraAsString,
        fetchPlanoData,
        plano,
        isPlanoCancelado,
        pagamentoStatus,
        fetchPagamentoWithInterval,
        fetchPagamentoStatus,
        clearFetchPagamentoWithInterval,
        hasSubscriptionInMobileApp,
      }}
    >
      {children}
    </PagamentoContext.Provider>
  );
}

export function usePagamento() {
  const context = useContext(PagamentoContext);
  const {
    cartoes,
    setCartoes,
    reloadCartoesSalvos,
    isLoading,
    selecionaCartao,
    planoDataExpira,
    getPlanoDataExpiraAsString,
    cancelarPlano,
    updatePlano,
    removerCartaoSalvo,
    fetchPlanoDataExpira,
    fetchPlanoCanceladoDataExpira,
    planoCanceladoDataExpira,
    getPlanoCanceladoDataExpiraAsString,
    fetchPlanoData,
    plano,
    isPlanoCancelado,
    pagamentoStatus,
    fetchPagamentoWithInterval,
    fetchPagamentoStatus,
    clearFetchPagamentoWithInterval,
    hasSubscriptionInMobileApp,
  } = context;

  return {
    cartoes,
    setCartoes,
    reloadCartoesSalvos,
    isLoading,
    selecionaCartao,
    planoDataExpira,
    getPlanoDataExpiraAsString,
    cancelarPlano,
    updatePlano,
    removerCartaoSalvo,
    fetchPlanoDataExpira,
    fetchPlanoCanceladoDataExpira,
    planoCanceladoDataExpira,
    getPlanoCanceladoDataExpiraAsString,
    fetchPlanoData,
    plano,
    isPlanoCancelado,
    pagamentoStatus,
    fetchPagamentoWithInterval,
    fetchPagamentoStatus,
    clearFetchPagamentoWithInterval,
    hasSubscriptionInMobileApp,
  };
}
