import api from "@api";
import moment from "moment";
import {buildUrlParams} from "@helpers";
import {log} from "@helpers";

const getBuscaTermoResultados = async (filtro, type, pagina = 1, setCurrentProgress) => {
  if (filtro && filtro.diariosSelecionados) {
    const diario = filtro.diariosSelecionados[0];
    try {
      const resultados = await getResultatoParaDiario(
        filtro,
        diario.id,
        diario.nome,
        pagina,
        setCurrentProgress,
        type
      );
      if (resultados) {
        return [resultados[0]];
      }
    } catch (error) {
      throw new Error(error);
    }
  }
};

const getResultatoParaDiario = async (
  filtro,
  diarioId,
  diarioNome,
  pagina,
  setCurrentProgress,
  type
) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  if (!currentUser) return;
  if (!diarioId) return;
  const urlFreeSearch = "/consulta_termos/_searchInfiniteScrollFree";
  const urlPaidSearch = "/consulta_termos/_searchInfiniteScroll";
  const url = `${process.env.REACT_APP_API_V2}${
    type === "free" ? urlFreeSearch : urlPaidSearch
  }?diario=${diarioId}&pagina=${pagina}&exato=true`;

  const queryObj = filtro
    ? {
        dataInicio: moment(filtro.dataInicio).format("DD/MM/YYYY"),
        dataFim: moment(filtro.dataFim).format("DD/MM/YYYY"),
        termo: filtro.searchString,
        cliente: currentUser.user.idCliente,
      }
    : {};

  const queryString = buildUrlParams(queryObj);

  log("QUERY", queryString);

  const payload = {
    pathWithDomain: `${url}&${queryString}`,
    method: "get",
  };

  log("payload", payload);

  try {
    const {data} = (await api(payload, setCurrentProgress)) ?? {data: []};
    const lista = [];

    if (data && data.lista && data.lista.length > 0) {
      lista.push({
        id: diarioId,
        titulo: diarioNome,
        total: data.total,
        registros: [],
      });
    }

    if (lista.length > 0) {
      data.lista.forEach((item, i) => {
        const index = lista.findIndex((d) => d.id === diarioId);

        lista[index].registros.push({
          data: item.dataFormatada,
          titulo: "",
          texto: item.texto,
          link: item.link,
          pagina: item.pagina,
        });
      });
    }
    return lista;
  } catch (error) {
    throw new Error(error);
  }
};

export default getBuscaTermoResultados;
