import React, {createContext, useState, useContext, useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {routeCodes} from "@constants/routes";
import {authenticationService} from "@services/authentication.service";

const BuscaTermoFiltroContext = createContext();

export default function BuscaTermoFiltroProvider({children}) {
  const history = useHistory();
  const location = useLocation();
  const searchQuery = location?.state?.q ?? "";
  const dataInicio = location?.state?.dataInicio ?? null;
  const dataFim = location?.state?.dataFim ?? null;
  const userEmail = authenticationService.currentUserValue?.user?.email;

  useEffect(() => {
    if (!!userEmail && !localStorage.getItem(`ultimasBuscas[${userEmail}]`)) {
      localStorage.setItem(`ultimasBuscas[${userEmail}]`, JSON.stringify([]));
    }
  });

  const filtroInit = {
    searchString: searchQuery,
    ultimasBuscas: localStorage.getItem(`ultimasBuscas[${userEmail}]`)
      ? JSON.parse(localStorage.getItem(`ultimasBuscas[${userEmail}]`))
      : [],
    dataInicio: dataInicio,
    dataFim: dataFim,
    dataFocus: null,
    diariosSelecionados: [],
    pagina: 1,
  };

  const [filtro, setFiltro] = useState(filtroInit);

  const limparFiltro = () => {
    setFiltro(filtroInit);
    history.push({
      pathname: routeCodes.SEARCH_RESULTS,
    });
  };

  const aplicarBuscaTermo = (value) => {
    setFiltro({...filtro, searchString: value});
  };

  const atualizaUltimasBuscas = (currentFiltro) => {
    try {
      if (!userEmail) {
        return;
      }
      const ultimasBuscas = JSON.parse(
        localStorage.getItem(`ultimasBuscas[${userEmail}]`) || ""
      );
      if (
        !ultimasBuscas.find((element) => {
          return element.toUpperCase() === filtro.searchString.toUpperCase();
        })
      ) {
        const tamanhoMaximoUltimasBuscas = 5;
        if (ultimasBuscas.length >= tamanhoMaximoUltimasBuscas) {
          ultimasBuscas.shift();
        }
        ultimasBuscas.push(filtro.searchString);
        localStorage.setItem(
          `ultimasBuscas[${userEmail}]`,
          JSON.stringify(ultimasBuscas)
        );
        setFiltro({...currentFiltro, ultimasBuscas: [...ultimasBuscas]});
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERRO localStorage", error);
    }
  };

  const removeBuscaHistorico = (currentFiltro) => {
    try {
      if (!userEmail) {
        return;
      }
      const ultimasBuscas = [...currentFiltro.ultimasBuscas];

      localStorage.setItem(`ultimasBuscas[${userEmail}]`, JSON.stringify(ultimasBuscas));

      setFiltro({...currentFiltro, ultimasBuscas: [...ultimasBuscas]});
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERRO localStorage", error);
    }
  };

  const selecionaDataPeriodo = (dataInicio, dataFim) => {
    setTimeout(() => {
      setFiltro({
        ...filtro,
        dataInicio: dataInicio?.toISOString(),
        dataFim: dataFim?.toISOString(),
      });
    }, 200);
  };
  const selecionaDataFocus = (dataFocus) => {
    setFiltro({...filtro, dataFocus});
  };

  const aplicarDiariosSelecionadosFiltro = (diariosSelecionados) => {
    setFiltro({...filtro, diariosSelecionados});
  };

  const setProximaPaginaValue = () => {
    setFiltro({...filtro, pagina: filtro.pagina + 1});
  };

  return (
    <BuscaTermoFiltroContext.Provider
      value={{
        filtro,
        setFiltro,
        limparFiltro,
        aplicarBuscaTermo,
        atualizaUltimasBuscas,
        selecionaDataPeriodo,
        selecionaDataFocus,
        aplicarDiariosSelecionadosFiltro,
        setProximaPaginaValue,
        removeBuscaHistorico,
      }}
    >
      {children}
    </BuscaTermoFiltroContext.Provider>
  );
}

export function useBuscaTermoFiltro() {
  const context = useContext(BuscaTermoFiltroContext);
  const {
    filtro,
    setFiltro,
    limparFiltro,
    aplicarBuscaTermo,
    atualizaUltimasBuscas,
    selecionaDataPeriodo,
    selecionaDataFocus,
    aplicarDiariosSelecionadosFiltro,
    setProximaPaginaValue,
    removeBuscaHistorico,
  } = context;

  return {
    filtro,
    setFiltro,
    limparFiltro,
    aplicarBuscaTermo,
    atualizaUltimasBuscas,
    selecionaDataPeriodo,
    selecionaDataFocus,
    aplicarDiariosSelecionadosFiltro,
    setProximaPaginaValue,
    removeBuscaHistorico,
  };
}
