import createAlerta from "@api/requests/alertas/createAlerta";
import {routeCodes} from "@constants/routes";
import {createContext, useContext, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {usePlanoAlertaModal} from "./PlanoAlertaModal";
import {toastErrorMessage, toastInfoMessage, toastSuccessMessage} from "@utils/toast";
import updateAlerta from "@api/requests/alertas/updateAlerta";
import deactivateAlerta from "@api/requests/alertas/desativarAlerta";
import deleteAlerta from "@api/requests/alertas/deletarAlerta";
import activateAlerta from "@api/requests/alertas/ativarAlerta";

const AlertaFiltroContext = createContext();

export default function AlertaFiltroProvider({children}) {
  const {openAlert} = usePlanoAlertaModal();
  const history = useHistory();
  const location = useLocation();
  const searchQuery = location?.state?.q ?? "";

  const filtroInit = {
    searchString: searchQuery,
  };

  const [filtro, setFiltro] = useState(filtroInit);

  const limparFiltro = () => {
    setFiltro({
      searchString: "",
    });
    history.push({
      pathname: routeCodes.ALERTAS,
    });
  };

  const aplicarBusca = (value) => {
    setFiltro({...filtro, searchString: value});
    history.push({
      pathname: routeCodes.ALERTAS_RESULTADOS,
      search: `?q=${value}`, // query string
      state: {
        // location state
        q: value,
      },
    });
  };

  const aplicarBuscaEditarAlerta = (alerta) => {
    setFiltro({...filtro, searchString: alerta.termo});
    history.push({
      pathname: routeCodes.ALERTAS_RESULTADOS,
      search: `?q=${alerta.termo}`, // query string
      state: {
        // location state
        q: alerta,
      },
    });
  };

  const criarAlerta = async (searchString, diariosSelecionados) => {
    try {
      await createAlerta({termo: searchString, diarios: diariosSelecionados});
      toastInfoMessage("Alerta criado.");
    } catch (error) {
      if (error.message) {
        openAlert({
          type: error.message,
          goBackOnClose: false,
        });
      }
    } finally {
      if (!(window.location.pathname === "/busca/resultados")) {
        history.push({
          pathname: routeCodes.ALERTAS,
        });
      }
    }
  };

  const atualizarAlerta = async (alerta, diariosSelecionados) => {
    try {
      await updateAlerta({alerta: alerta, diariosSelecionados: diariosSelecionados});
      toastInfoMessage("Alerta atualizado.");
    } catch (error) {
      if (error.message) {
        openAlert({
          type: error.message,
          goBackOnClose: false,
        });
      }
    }
    history.push(routeCodes.ALERTAS);
  };

  const desativarAlerta = async (data) => {
    const {id, termo} = data;
    try {
      await deactivateAlerta({
        termo: termo,
        id: id,
      });
      toastInfoMessage("Alerta desativado");
    } catch (error) {
      if (error.message) {
        openAlert({
          type: error.message,
          goBackOnClose: false,
        });
      }
    }
  };

  const deletarAlerta = async (data) => {
    try {
      await deleteAlerta(data.id);
      toastErrorMessage("Alerta deletado");
    } catch (error) {
      if (error.message) {
        openAlert({
          type: error.message,
          goBackOnClose: false,
        });
      }
    }
  };

  const ativarAlerta = async (data) => {
    try {
      await activateAlerta({termo: data.termo, id: data.id});
      toastInfoMessage("Alerta ativado");
    } catch (error) {
      if (error.message) {
        openAlert({
          type: error.message,
          goBackOnClose: false,
        });
      }
    }
  };

  return (
    <AlertaFiltroContext.Provider
      value={{
        filtro,
        setFiltro,
        limparFiltro,
        aplicarBusca,
        aplicarBuscaEditarAlerta,
        criarAlerta,
        atualizarAlerta,
        deletarAlerta,
        desativarAlerta,
        ativarAlerta,
      }}
    >
      {children}
    </AlertaFiltroContext.Provider>
  );
}

export function useAlertaFiltro() {
  const context = useContext(AlertaFiltroContext);
  const {
    filtro,
    setFiltro,
    limparFiltro,
    aplicarBusca,
    criarAlerta,
    aplicarBuscaEditarAlerta,
    atualizarAlerta,
    deletarAlerta,
    desativarAlerta,
    ativarAlerta,
  } = context;

  return {
    filtro,
    setFiltro,
    limparFiltro,
    aplicarBusca,
    aplicarBuscaEditarAlerta,
    criarAlerta,
    atualizarAlerta,
    deletarAlerta,
    desativarAlerta,
    ativarAlerta,
  };
}
