import api from "@api";

import getPlano from "./../planos/getPlano";

import {log} from "@helpers";
import {GRATUITO} from "@constants/planos";

const getPlanoExpiraData = async (setCurrentProgress) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  if (!currentUser) return;

  const plano = await getPlano();
  const idPlanoUsuario = plano.data.id;
  // Plano gratuito não tem data de expiração (Backend retorna 500 nesse caso)
  if (idPlanoUsuario <= GRATUITO.id) return;

  const payload = {
    pathWithDomain: `${process.env.REACT_APP_API_V2}/api/v1/forma_pagamento/subscription_expiration`,
    method: "GET",
  };
  log("GET getPlanoExpiraData:", payload);

  return api(payload, setCurrentProgress);
};

export default getPlanoExpiraData;
