const GRATUITO_ID = 1;
const BASICO_MENSAL_ID = 2;
const BASICO_ANUAL_ID = 5;
const PREMIUM_MENSAL_ID = 3;
const PREMIUM_ANUAL_ID = 6;
const ILIMITADO_ID = 7;

export const BASICO_MENSAL = {
  id: BASICO_MENSAL_ID,
  valor: "14,90",
  // valor: "7,45",
  // valorSemDesconto: "14,90",
};
export const BASICO_ANUAL = {
  id: BASICO_ANUAL_ID,
  valor: "142,90",
  valorSemDesconto: "178,80",
  // valor: "71,45",
  // valorSemDesconto: "142,90",
};
export const PREMIUM_MENSAL = {
  id: PREMIUM_MENSAL_ID,
  valor: "19,90",
  // valor: "9,95",
  // valorSemDesconto: "19,90",
};
export const PREMIUM_ANUAL = {
  id: PREMIUM_ANUAL_ID,
  valor: "190,90",
  valorSemDesconto: "238,80",
  // valor: "95,45",
  // valorSemDesconto: "190,90",
};
export const GRATUITO = {
  id: GRATUITO_ID,
  valor: "0,00",
};
export const ILIMITADO = {
  id: ILIMITADO_ID,
  valor: "0,00",
};
