import api from "@api";

import {log} from "@helpers";

const checkDownloadUser = () => {
  const payload = {
    pathWithDomain: `${process.env.REACT_APP_API_V2}/v3/clientes/checkDownloadUser`,
    method: "get",
  };

  log("checkDownloadUser", payload);

  return api(payload);
};

export default checkDownloadUser;
