import verificarConta from "@api/requests/auth/verificarConta";
import {createContext, useContext, useEffect, useState} from "react";
import {authenticationService} from "../services/authentication.service";
const AuthContext = createContext();

export default function AuthProvider({children}) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [registerError, setRegisterError] = useState("");

  useEffect(() => {
    const authUser = authenticationService.currentUserValue;

    if (!authUser || !authUser.token.accessToken) {
      setIsAuthenticated(false);
    } else {
      setIsAuthenticated(true);
    }
  }, []);

  const login = async ({email, senha}) => {
    return authenticationService
      .login({email, senha})
      .then((res) => {
        setIsAuthenticated(true);
        return res;
      })
      .catch((err) => {
        setIsAuthenticated(false);
        /*
        como o backend esta enviando ISO-8859-1 em vez de UTF-8, acentuação vem com erro
        response header:
        content-type: application/json;charset=ISO-8859-1
        */
        if (err.match(/Email ou senha inv.lidos/gm)) {
          err = "Email ou senha inválidos";
        }
        if (
          err.match(
            /Seu endere.o de email ainda n.o foi validado. Verifique sua caixa de entrada para confirmar seu endere.o de email/gm
          )
        ) {
          err =
            "Seu endereço de email ainda não foi validado. Verifique sua caixa de entrada para confirmar seu endereço de email";
        }
        setLoginError(err);
        return null;
      });
  };

  const register = async ({email, senha, name}) => {
    return authenticationService
      .register({email, senha, name})
      .then((res) => {
        setRegisterError(null);
        return res;
      })
      .catch((err) => {
        setIsAuthenticated(false);
        setRegisterError(err);
        return null;
      });
  };

  const accountVerify = async ({token}) => {
    return verificarConta({token});
  };

  const currentUser = () => {
    return authenticationService.currentUserValue;
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        logout: authenticationService.logout,
        register,
        currentUser,
        loginError,
        registerError,
        accountVerify,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  const {
    isAuthenticated,
    login,
    register,
    logout,
    currentUser,
    loginError,
    registerError,
    accountVerify,
  } = context;

  return {
    isAuthenticated,
    login,
    register,
    logout,
    currentUser,
    loginError,
    registerError,
    accountVerify,
  };
}
