import React from "react";
import {Formik} from "formik";
import checkMark from "@images/check-mark.png";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "../../../Atom/Button";
import Container from "react-bootstrap/Container";
import "./../LoginForm.scss";
import "./CadastroForm.scss";

function CadastroForm({
  submitRegister,
  showMsgEmailValidacao,
  handleFecharEmailValidacao,
}) {
  return (
    <div>
      {showMsgEmailValidacao ? (
        <div className="success-message">
          <img
            src={checkMark}
            width="50"
            alt="Um e-mail de verificação foi enviado para você validar seu cadastro!"
            className="mb-4"
          />
          <div className="message-wrp">
            Um e-mail de verificação foi enviado para você validar seu cadastro!
          </div>
          <div className="mt-4">
            <span onClick={handleFecharEmailValidacao} className="btn-voltar">
              Voltar
            </span>
          </div>
        </div>
      ) : (
        <>
          <Formik
            initialValues={{email: "", senha: "", confirmarSenha: ""}}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Campo Obrigatório";
              } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                errors.email = "Email inválido";
              }
              if (!values.name || values.name === "") errors.name = "Campo Obrigatório";

              if (!values.senha || values.senha === "") {
                errors.senha = "Campo Obrigatório";
              } else if (values.senha.length < 4) {
                errors.senha = "Use 4 ou mais caracteres";
              }

              if (!values.confirmarSenha || values.confirmarSenha === "") {
                errors.confirmarSenha = "Campo Obrigatório";
              } else if (values.confirmarSenha !== values.senha) {
                errors.confirmarSenha = "As senhas não coincidem";
              }

              return errors;
            }}
            onSubmit={async (values, {setSubmitting}) => {
              await submitRegister(values);
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form className="login-form" onSubmit={handleSubmit}>
                <Form.Group controlId="validationFormikNome" className="mt-4">
                  <Form.Label>Nome</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      placeholder="Nome"
                      aria-describedby="inputGroupPrepend"
                      name="name"
                      isInvalid={!!errors.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name && touched.name && errors.name}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId="validationFormikEmailCadastro" className="mt-4">
                  <Form.Label>E-mail</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      placeholder="E-mail"
                      aria-describedby="inputGroupPrepend"
                      name="email"
                      isInvalid={!!errors.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email && touched.email && errors.email}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group controlId="validationFormikSenha" className="mt-4">
                  <Form.Label>Senha</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="password"
                      placeholder="Senha"
                      aria-describedby="inputGroupPrepend"
                      name="senha"
                      isInvalid={!!errors.senha}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.senha}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.senha && touched.senha && errors.senha}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group
                  controlId="validationFormikConfirmarSenha"
                  className="mt-4 mb-5"
                >
                  <Form.Label>Confirmar senha</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="password"
                      placeholder="Confirmar senha"
                      aria-describedby="inputGroupPrepend"
                      name="confirmarSenha"
                      isInvalid={!!errors.confirmarSenha}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirmarSenha}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.confirmarSenha &&
                        touched.confirmarSenha &&
                        errors.confirmarSenha}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Button
                  type="submit"
                  disabled={isSubmitting}
                  className="adoo-btn--full-width adoo-btn--md adoo-btn--bold adoo-btn--color-tertiary mt-4"
                >
                  CRIAR CONTA
                </Button>
              </Form>
            )}
          </Formik>

          <Container className="mb-5"></Container>
        </>
      )}
    </div>
  );
}

export default CadastroForm;
