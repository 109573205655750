import React, {Component} from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";
import App from "@containers/App";
import {routeCodes} from "@constants/routes";
import {history} from "@helpers";
import ProtectedRoute from "@components/ProtectedRoute";

import {ToastContainer} from "react-toastify";

const AsyncComponentHOC = (importComponent) => {
  class AsyncComponent extends Component {
    state = {
      component: null,
    };

    async componentWillMount() {
      const {default: component} = await importComponent();
      this.setState({
        component,
      });
    }

    render() {
      const C = this.state.component;

      return C ? <C {...this.props} /> : null;
    }
  }

  return AsyncComponent;
};

const AsyncHome = AsyncComponentHOC(() => import("@containers/Home"));
const AsyncBusca = AsyncComponentHOC(() => import("@containers/Search"));
const AsyncBuscaResultados = AsyncComponentHOC(() =>
  import("@containers/Search/BuscaResultados")
);
const AsyncBuscaSelecionarDiario = AsyncComponentHOC(() =>
  import("@containers/Search/BuscaSelecionarDiario")
);
const AsyncBuscarTermoSelecionarData = AsyncComponentHOC(() =>
  import("@containers/Search/BuscarTermoSelecionarData")
);
const AsyncViewer = AsyncComponentHOC(() => import("@containers/ConteudoViewer"));
const AsyncNavegacao = AsyncComponentHOC(() => import("@containers/Navegacao"));
const AsyncNavegacaoDetalhes = AsyncComponentHOC(() =>
  import("@containers/Navegacao/NavegacaoDetalhes")
);
const AsyncAlertas = AsyncComponentHOC(() => import("@containers/Alertas"));
const AsyncAlertasResultados = AsyncComponentHOC(() =>
  import("@containers/Alertas/AlertasResultados")
);
const AsyncAlertasResultadosHistorico = AsyncComponentHOC(() =>
  import("@containers/Alertas/AlertasResultadosHistorico")
);
const AsyncMinhaConta = AsyncComponentHOC(() => import("@containers/MinhaConta"));
const AsyncMinhaContaPlanos = AsyncComponentHOC(() =>
  import("@containers/MinhaContaPlanos")
);
const AsyncSuporte = AsyncComponentHOC(() => import("@containers/Suporte"));
const AsyncRecoveryPassword = AsyncComponentHOC(() =>
  import("@containers/RecoveryPassword")
);
const AsyncTermosDeUso = AsyncComponentHOC(() => import("@containers/TermosDeUso"));
const AsyncPoliticaDePrivacidade = AsyncComponentHOC(() =>
  import("@containers/PoliticaDePrivacidade")
);
const AsyncConsultaProcessual = AsyncComponentHOC(() =>
  import("@containers/ConsultaProcessual")
);
const AsyncVerificarConta = AsyncComponentHOC(() =>
  import("@containers/VerificarConta/VerificarConta")
);

const Routes = ({childProps}) => {
  return (
    <Router history={history}>
      <ToastContainer
        limit={1}
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
      <App>
        <Switch>
          <Route path={"/"} exact component={AsyncHome} props={childProps} />
          <ProtectedRoute
            path={routeCodes.SEARCH_RESULTS_DIARIOS}
            component={AsyncBuscaSelecionarDiario}
            props={childProps}
            typeRightPanel={"buscar-termo"}
          />
          <ProtectedRoute
            path={routeCodes.SEARCH_RESULTS_DATA}
            component={AsyncBuscarTermoSelecionarData}
            props={childProps}
            typeRightPanel={"buscar-termo"}
          />
          <ProtectedRoute
            path={routeCodes.SEARCH_RESULTS}
            component={AsyncBuscaResultados}
            props={childProps}
            typeRightPanel={"buscar-termo"}
          />
          <Route path={routeCodes.SEARCH} component={AsyncBusca} props={childProps} />
          <Route path={routeCodes.VIEWER} component={AsyncViewer} props={childProps} />
          <Route
            path={routeCodes.RECOVERY_PASSWORD}
            component={AsyncRecoveryPassword}
            props={childProps}
          />
          <ProtectedRoute
            path={routeCodes.ALERTAS_RESULTADOS}
            component={AsyncAlertasResultados}
            props={childProps}
            typeRightPanel={"alertas"}
          />
          <ProtectedRoute
            path={routeCodes.ALERTAS_RESULTADOS_HISTORICO}
            component={AsyncAlertasResultadosHistorico}
            props={childProps}
            typeRightPanel={"alertas"}
          />
          <Route path={routeCodes.ALERTAS} component={AsyncAlertas} props={childProps} />
          <Route
            path={routeCodes.NAVEGACAO_DETALHES}
            component={AsyncNavegacaoDetalhes}
            props={childProps}
          />
          <Route
            path={routeCodes.NAVEGACAO_PODER_ESFERA}
            component={AsyncNavegacao}
            props={childProps}
          />
          <Route
            path={routeCodes.NAVEGACAO}
            component={AsyncNavegacao}
            props={childProps}
          />
          <Route
            path={routeCodes.SUPORTE_RELATAR_ERRO}
            component={AsyncSuporte}
            props={childProps}
          />
          <Route
            path={routeCodes.SUPORTE_AJUDA}
            component={AsyncSuporte}
            props={childProps}
          />
          <Route
            path={routeCodes.TERMOS_USO}
            component={AsyncTermosDeUso}
            props={childProps}
          />
          <Route
            path={routeCodes.POLITICA_PRIVACIDADE}
            component={AsyncPoliticaDePrivacidade}
            props={childProps}
          />
          <ProtectedRoute
            path={routeCodes.GERAL_EDITAR_PERFIL}
            component={AsyncMinhaConta}
            props={childProps}
          />
          <ProtectedRoute
            path={routeCodes.GERAL_ALTERAR_SENHA}
            component={AsyncMinhaConta}
            props={childProps}
          />
          <ProtectedRoute
            path={routeCodes.GERAL_EMAIL_SMS}
            component={AsyncMinhaConta}
            props={childProps}
          />
          <ProtectedRoute
            path={routeCodes.PLANOS_PRINCIPAL}
            component={AsyncMinhaContaPlanos}
            props={childProps}
          />
          <ProtectedRoute
            path={routeCodes.PLANOS_PAGAMENTOS}
            component={AsyncMinhaContaPlanos}
            props={childProps}
          />
          <ProtectedRoute
            path={routeCodes.INDIQUE_E_GANHE}
            component={AsyncMinhaContaPlanos}
            props={childProps}
          />
          <Route
            path={routeCodes.CONSULTA_PROCESSUAL}
            component={AsyncConsultaProcessual}
            props={childProps}
          />
          <Route
            path={routeCodes.VERIFICAR_CONTA}
            component={AsyncVerificarConta}
            props={childProps}
          />
          <Route path="*" component={NotFoundRoute} props={childProps} />
        </Switch>
      </App>
    </Router>
  );
};

function NotFoundRoute() {
  const location = useLocation();
  return <Redirect to="/" state={{from: location}} />;
}

export default Routes;
