import api from "@api";

function sendEmailEsqueciSenha(email, token, setCurrentProgress) {
  const payload = {
    pathWithDomain: `${process.env.REACT_APP_API_V2}/v3/clientes/recovery-password`,
    method: "POST",
    body: {
      email,
      recaptcha: token,
    },
  };
  return api(payload, setCurrentProgress);
}

export default sendEmailEsqueciSenha;
