import api from "@api";

import {buildUrlParams} from "@helpers";

import {log} from "@helpers";

const getDiarios = (filtro, setCurrentProgress) => {
  const queryObj = filtro
    ? {
        poder: filtro.poder,
        esfera: filtro.esfera.join(","),
        dataInicio: filtro.dataInicio,
        dataFim: filtro.dataFim,
        sort: `${filtro.ordenacao.key},${filtro.ordenacao.value}`,
      }
    : {};

  const queryString = buildUrlParams(queryObj);

  log("QUERY", queryString);

  if (queryString) {
    const payload = {
      pathWithDomain: `${process.env.REACT_APP_API_V2}/diario/findDiarioByPoderAndEsfera?${queryString}`,
      method: "get",
    };

    if (filtro.esfera.length) {
      return api(payload, setCurrentProgress);
    }
  }
  return false;
};

export default getDiarios;
