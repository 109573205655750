/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import {BehaviorSubject} from "rxjs";
import {
  getAuth,
  signInWithPopup,
  FacebookAuthProvider,
  GoogleAuthProvider,
} from "firebase/auth";
import jwt_decode from "jwt-decode";
import getLoggedInUserData from "@api/requests/auth/getLoggedInUserData";
import moment from "moment";

import doLoginSocialMedia from "@api/requests/auth/doLoginSocialMedia";

async function socialMediaAuth(provider) {
  const auth = getAuth();
  signInWithPopup(auth, provider)
    .then((result) => {
      // The signed-in user info.
      const user = result.user;
      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      const credential = getProviderInstance(provider).credentialFromResult(result);
      const accessToken = credential.accessToken;

      let userLoginSocialMedia = {
        token: user.accessToken,
        email: user.email,
        nome: user.displayName,
        sso: provider.providerId.replace(".com", ""),
      };

      doLoginSocialMedia(userLoginSocialMedia).then(async (authData) => {
        const {headers} = authData;

        const accessToken = headers.get("authorization");
        const refreshToken = headers.get("refresh_token");

        const tokenRaw = accessToken.replace("Bearer ", "");
        const decoded = jwt_decode(tokenRaw);

        const {data: user} = await getuserDataByAccessToken(accessToken);

        const auth = {
          token: {
            accessToken,
            refreshToken,
            expireDate: moment.unix(decoded.exp).toISOString(),
          },
          user: {
            idCliente: user.idCliente,
            nome: user.nome,
            email: user.email,
            idPlano: user.plano.id,
            linkImagem: user.linkImagem
              ? user.linkImagem
              : `https://ui-avatars.com/api/?name=${user.nome}&length=1&background=6034c7&color=fff`,
          },
        };

        persistUserAuthLocalStorage(auth);
        window.location.reload();
        return auth;
      });

      return true;

      // ...
    })
    .catch((error) => {
      console.log("error", error);

      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = getProviderInstance(provider).credentialFromError(error);
    });
}

function persistUserAuthLocalStorage(auth) {
  localStorage.setItem("currentUser", JSON.stringify(auth));
  currentUserSubject.next(auth);
}

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
);

function getuserDataByAccessToken(accessToken) {
  return getLoggedInUserData(accessToken).then((data) => {
    return data;
  });
}

const getProviderInstance = (provider) => {
  switch (provider.providerId) {
    case "facebook.com":
      return FacebookAuthProvider;
    case "google.com":
      return GoogleAuthProvider;
    default:
      return FacebookAuthProvider;
  }
};

export default socialMediaAuth;
