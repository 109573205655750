import {BehaviorSubject} from "rxjs";
import jwt_decode from "jwt-decode";
import moment from "moment";
import doLogin from "../api/requests/auth/doLogin";
import doRegister from "../api/requests/auth/doRegister";
import getLoggedInUserData from "../api/requests/auth/getLoggedInUserData";

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
);

export const authenticationService = {
  login,
  register,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
  updateUserLocalData,
  persistUserAuthLocalStorage,
};

async function login({email, senha}) {
  return doLogin({email, senha}).then(async (authData) => {
    const {headers} = authData;

    const accessToken = headers.get("authorization");
    const refreshToken = headers.get("refresh_token");

    const tokenRaw = accessToken.replace("Bearer ", "");
    const decoded = jwt_decode(tokenRaw);

    const {data: user} = await getuserDataByAccessToken(accessToken);

    const auth = {
      token: {
        accessToken,
        refreshToken,
        expireDate: moment.unix(decoded.exp).toISOString(),
      },
      user: {
        idCliente: user.idCliente,
        nome: user.nome,
        email: user.email,
        linkImagem: user.linkImagem
          ? user.linkImagem
          : `https://ui-avatars.com/api/?name=${user.nome}&length=1&background=6034c7&color=fff`,
      },
    };

    persistUserAuthLocalStorage(auth);
    return auth;
  });
}

async function register({email, senha, name}) {
  return doRegister({email, senha, name}).then((auth) => {
    return auth;
  });
}

function logout() {
  localStorage.removeItem("currentUser");
  currentUserSubject.next(null);
}

function getuserDataByAccessToken(accessToken) {
  return getLoggedInUserData(accessToken).then((data) => {
    return data;
  });
}

function persistUserAuthLocalStorage(auth) {
  localStorage.setItem("currentUser", JSON.stringify(auth));
  currentUserSubject.next(auth);
}

function updateUserLocalData({nome, imagem, telefone}) {
  const auth = {
    ...currentUserSubject.value,
    user: {
      ...currentUserSubject.value.user,
      nome,
      linkImagem: imagem ? imagem : currentUserSubject.value.user.linkImagem,
      telefone,
    },
  };

  persistUserAuthLocalStorage(auth);
}
