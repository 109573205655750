import api from "@api";
import {log} from "@helpers";

const getHistoricoDeAlertas = ({mes, ano, setCurrentProgress}) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  if (!currentUser) return;
  const payload = {
    pathWithDomain: `${process.env.REACT_APP_API_V2}/historico_alerta/busca?mes=${mes}&ano=${ano}`,
    method: "get",
  };
  log("Listar histórico de alertas payload: ", payload);

  return api(payload, setCurrentProgress);
};

export default getHistoricoDeAlertas;
