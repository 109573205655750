import React from "react";
import {useAuth} from "@context/Auth";
import socialMediaAuth from "@services/socialMediaAuth.service";
import LoginModal from "@adoo-ui/Organism/LoginModal";
import * as analytics from "@helpers/ga4";

function Login({onHide, onSuccessLogin, typeRightPanel, ...rest}) {
  const {login, register, loginError, registerError} = useAuth();

  const socialMediaLogin = async (provider) => {
    analytics.actionTrack("Login:social", false);
    await socialMediaAuth(provider);
  };

  const registerWithSocial = (provider) => {
    // TODO: integrate with api
    // console.log("HERE registerWithSocial!", provider);
  };

  return (
    <LoginModal
      {...rest}
      onHide={onHide}
      onSuccessLogin={onSuccessLogin}
      login={login}
      register={register}
      loginError={loginError}
      registerError={registerError}
      socialMediaLogin={socialMediaLogin}
      registerWithSocial={registerWithSocial}
      typeRightPanel={typeRightPanel}
    />
  );
}

export default Login;
