import React, {createContext, useState, useContext, useEffect} from "react";
import {useLoadingBar} from "@context/LoadingBar";
import getCadernos, {agruparCadernos} from "@api/requests/diarios/getCadernos";

import {log} from "@helpers";
import getCadernoByData from "@api/requests/diarios/getCadernoByData";

const CadernoContext = createContext();

export default function CadernoProvider({children}) {
  const {setCurrentProgress} = useLoadingBar();
  const [cadernos, setCadernos] = useState([]);
  const [cadernosNaoAgrupados, setCadernosNaoAgrupados] = useState([]);
  const [page, setPage] = useState(0);
  const [cadernosFiltradosPage, setCadernosFiltradosPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    reloadCadernos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadCadernos = async (filtro, diarioSelecionado) => {
    setIsLoading(true);
    log("Reload API cadernos", {filtro, diarioSelecionado, page});
    let cadernos;

    if (filtro?.dataInicio === undefined && filtro?.dataFim === undefined) {
      cadernos = await getCadernos(filtro, diarioSelecionado, page, setCurrentProgress);
    } else {
      cadernos = await getCadernoByData(
        filtro,
        diarioSelecionado,
        cadernosFiltradosPage,
        setCurrentProgress
      );
    }

    setCadernos(agruparCadernos(cadernos));
    setCadernosNaoAgrupados(cadernos);
    setIsLoading(false);
  };

  const fetchNextPage = async (filtro, diarioSelecionado) => {
    let cadernosPagination;
    if (filtro?.dataInicio === undefined && filtro?.dataFim === undefined) {
      const currentPage = page + 1;
      setPage(currentPage);
      cadernosPagination = await getCadernos(
        filtro,
        diarioSelecionado,
        currentPage,
        setCurrentProgress
      );
    } else {
      const currentPage = cadernosFiltradosPage + 1;
      setCadernosFiltradosPage(currentPage);
      cadernosPagination = await getCadernoByData(
        filtro,
        diarioSelecionado,
        currentPage,
        setCurrentProgress
      );
    }
    const novaLista = agruparCadernos([...cadernosNaoAgrupados, ...cadernosPagination]);
    setCadernos(novaLista);
    setCadernosNaoAgrupados([...cadernosNaoAgrupados, ...cadernosPagination]);
  };

  return (
    <CadernoContext.Provider
      value={{
        cadernos,
        setCadernos,
        reloadCadernos,
        fetchNextPage,
        setCadernosFiltradosPage,
        isLoading,
      }}
    >
      {children}
    </CadernoContext.Provider>
  );
}

export function useCaderno() {
  const context = useContext(CadernoContext);
  const {
    cadernos,
    setCadernos,
    reloadCadernos,
    fetchNextPage,
    setCadernosFiltradosPage,
    isLoading,
  } = context;

  return {
    cadernos,
    setCadernos,
    reloadCadernos,
    fetchNextPage,
    setCadernosFiltradosPage,
    isLoading,
  };
}
