import React from "react";
import {Formik} from "formik";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "../../Atom/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import "./LoginForm.scss";
import googleIcon from "@images/google-icon.svg";
// import facebookIcon from "@images/facebook-icon.png";
// import {facebookProvider} from "./../../../../config/authMethods";
import {googleProvider} from "./../../../../config/authMethods";

function LoginForm({
  submitLogin,
  loginWithSocial,
  handleAbrirEsqueciMinhaSenha,
  showCadastro,
}) {
  return (
    <div>
      <Formik
        initialValues={{email: "", senha: ""}}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Campo Obrigatório";
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = "Email inválido";
          }
          return errors;
        }}
        onSubmit={async (values, {setSubmitting}) => {
          await submitLogin(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} className="login-form">
            <Form.Group controlId="validationFormikEmailLogin" className="mt-4">
              <Form.Label>E-mail</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder="E-mail"
                  aria-describedby="inputGroupPrepend"
                  name="email"
                  isInvalid={!!errors.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email && touched.email && errors.email}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <Form.Group controlId="validationFormikSenha" className="mt-4">
              <Form.Label>Senha</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="password"
                  placeholder="Senha"
                  aria-describedby="inputGroupPrepend"
                  name="senha"
                  isInvalid={!!errors.senha}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.senha}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.senha && touched.senha && errors.senha}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mt-4 mx-3" controlId="formBasicCheckbox">
              <Form.Check type="checkbox" label="Mantenha-me conectado" />
            </Form.Group>
            <Button
              type="submit"
              disabled={isSubmitting}
              className="adoo-btn--full-width adoo-btn--md adoo-btn--bold adoo-btn--color-tertiary mt-4"
            >
              ENTRAR
            </Button>
          </Form>
        )}
      </Formik>

      <Container className="mt-3">
        <Row>
          <Col>
            <hr />
          </Col>
          <Col>
            <span className="entrar-com-label">ou entrar com</span>
          </Col>
          <Col>
            <hr />
          </Col>
        </Row>

        <Row className="mt-3">
          <div className="rede-social-login">
            <div
              className="btn-entrar-rede-social col-auto"
              onClick={() => loginWithSocial(googleProvider)}
            >
              <img src={googleIcon} alt="Entrar com Google" />
              Google
            </div>
          </div>
        </Row>

        <hr className="mt-5" />

        <div className="bottom-links-wrp">
          <div className="botton-link" onClick={handleAbrirEsqueciMinhaSenha}>
            Esqueceu a senha?
          </div>
          <div className="botton-link" onClick={showCadastro}>
            Cadastre-se
          </div>
        </div>
      </Container>
    </div>
  );
}

export default LoginForm;
