import {
  BASICO_ANUAL,
  BASICO_MENSAL,
  GRATUITO,
  ILIMITADO,
  PREMIUM_ANUAL,
  PREMIUM_MENSAL,
} from "@constants/planos";

export enum ReturnCheckIsUpgradeDowngrade {
  downgrade = "downgrade",
  upgrade = "upgrade",
  pagamento = "pagamento",
  default = "default",
}

export const checkIsUpgradeDowngrade = (
  currentPlanoId: number,
  targePlanoId: number
): ReturnCheckIsUpgradeDowngrade => {
  if (currentPlanoId === GRATUITO.id && targePlanoId === BASICO_MENSAL.id) {
    return ReturnCheckIsUpgradeDowngrade.pagamento;
  }

  if (currentPlanoId === GRATUITO.id && targePlanoId === PREMIUM_MENSAL.id) {
    return ReturnCheckIsUpgradeDowngrade.pagamento;
  }

  if (currentPlanoId === GRATUITO.id && targePlanoId === BASICO_ANUAL.id) {
    return ReturnCheckIsUpgradeDowngrade.pagamento;
  }

  if (currentPlanoId === GRATUITO.id && targePlanoId === PREMIUM_ANUAL.id) {
    return ReturnCheckIsUpgradeDowngrade.pagamento;
  }

  // ===============
  if (currentPlanoId === BASICO_MENSAL.id && targePlanoId === PREMIUM_MENSAL.id) {
    return ReturnCheckIsUpgradeDowngrade.upgrade;
  }

  if (currentPlanoId === BASICO_MENSAL.id && targePlanoId === PREMIUM_ANUAL.id) {
    return ReturnCheckIsUpgradeDowngrade.upgrade;
  }

  // ===============

  if (currentPlanoId === PREMIUM_MENSAL.id && targePlanoId === BASICO_MENSAL.id) {
    return ReturnCheckIsUpgradeDowngrade.downgrade;
  }

  if (currentPlanoId === PREMIUM_MENSAL.id && targePlanoId === BASICO_ANUAL.id) {
    return ReturnCheckIsUpgradeDowngrade.downgrade;
  }

  if (currentPlanoId === BASICO_ANUAL.id && targePlanoId === PREMIUM_MENSAL.id) {
    return ReturnCheckIsUpgradeDowngrade.downgrade;
  }

  if (currentPlanoId === PREMIUM_ANUAL.id && targePlanoId === PREMIUM_MENSAL.id) {
    return ReturnCheckIsUpgradeDowngrade.downgrade;
  }

  if (currentPlanoId === PREMIUM_ANUAL.id && targePlanoId === BASICO_ANUAL.id) {
    return ReturnCheckIsUpgradeDowngrade.downgrade;
  }

  return ReturnCheckIsUpgradeDowngrade.default;
};
