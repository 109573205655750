import React, {useState} from "react";
import {Route} from "react-router-dom";
import Login from "@containers/Login";
import {useAuth} from "@context/Auth";
import * as analytics from "@helpers/ga4";

// @TODO: move to containers
function ProtectedRoute({component: Component, typeRightPanel, ...rest}) {
  const {isAuthenticated} = useAuth();
  const [isLoginFormVisible, setIsLoginFormVisible] = useState(!isAuthenticated);

  const onSuccessLogin = () => {
    analytics.actionTrack("Login:protectedRoute:success", false);
    setIsLoginFormVisible(false);
    window.location.reload();
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated && isLoginFormVisible) {
          return (
            <div>
              <Login
                show={isLoginFormVisible}
                onSuccessLogin={onSuccessLogin}
                onHide={({action}) => {
                  setIsLoginFormVisible(false);
                  if (action === "reload-page") {
                    window.location.reload();
                  } else if (action === "go-back") {
                    window.history.back();
                  }
                }}
                typeRightPanel={typeRightPanel}
              />
              <Component {...props} unauthenticated={true} />;
            </div>
          );
        }

        return <Component {...props} />;
      }}
    />
  );
}

export default ProtectedRoute;
