import React, {createContext, useState, useContext, useEffect} from "react";

import {log} from "@helpers";
import {useLoadingBar} from "@context/LoadingBar";
import {useAuth} from "@context/Auth";
import getDiarios from "@api/requests/diarios/getDiarios";
import sendEmailDiarioOficial from "@api/requests/diarios/sendEmailDiarioOficial";
import checkDownloadUser from "@api/requests/diarios/checkDownloadUser";
import getAllDiarios from "@api/requests/diarios/getAllDiarios";

const DiariosOficiaisContext = createContext();

export default function DiariosOficiaisProvider({children}) {
  const {isAuthenticated} = useAuth();
  const {setCurrentProgress} = useLoadingBar();
  const [diarios, setDiarios] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    reloadDiarios();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSendEmail = async (
    diarioId,
    data,
    cbLoginForm,
    openAlert,
    openToastCb,
    cbSendEmail
  ) => {
    if (isAuthenticated) {
      sendEmail({data: data, id: diarioId}, openAlert, openToastCb, cbSendEmail);
    }

    cbLoginForm(!isAuthenticated);
  };

  async function sendEmail(conteudo, openAlert, openToastCb, cbSendEmail) {
    await sendEmailDiarioOficial(conteudo)
      .then((res) => {
        cbSendEmail(false);
      })
      .catch((error) => {
        if (error.message) {
          let type = error.message?.replaceAll("Error: ", "");
          openAlert({
            type,
            goBackOnClose: false,
          });
        } else {
          openToastCb(error);
        }
      });
  }

  const handleDownloadDiario = (link, openAlert, cb) => {
    cb(!isAuthenticated);

    if (isAuthenticated) {
      checkDownloadUser()
        .then((res) => {
          const canDownload = res.data;
          if (canDownload) {
            window.open(link, "_blank").focus();
          } else {
            openAlert({type: "download", goBackOnClose: false});
          }
        })
        .catch(() => {
          window.open(link, "_blank").focus();
        });
    }
  };

  const reloadDiarios = async (filtro) => {
    log("Reload API diarios", filtro);
    setIsLoading(true);
    const {data: diarios} = await getDiarios(filtro, setCurrentProgress);
    setDiarios(diarios || []);
    setIsLoading(false);
  };

  const applyFilterString = (value) => {
    setSearchString(value);
  };

  const getValueSearchString = () => {
    return searchString;
  };

  const diariosFiltered = diarios.filter((diario) =>
    diario.nome
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .includes(
        getValueSearchString()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
      )
  );

  return (
    <DiariosOficiaisContext.Provider
      value={{
        diarios,
        setDiarios,
        reloadDiarios,
        isLoading,
        handleSendEmail,
        handleDownloadDiario,
        applyFilterString,
        getValueSearchString,
        diariosFiltered,
        setSearchString,
        searchString,
      }}
    >
      {children}
    </DiariosOficiaisContext.Provider>
  );
}

export function useDiariosOficiais() {
  const context = useContext(DiariosOficiaisContext);
  const {
    diarios,
    setDiarios,
    reloadDiarios,
    isLoading,
    handleSendEmail,
    handleDownloadDiario,
    applyFilterString,
    getValueSearchString,
    diariosFiltered,
    setSearchString,
    searchString,
  } = context;

  return {
    diarios,
    setDiarios,
    reloadDiarios,
    isLoading,
    handleSendEmail,
    handleDownloadDiario,
    applyFilterString,
    getValueSearchString,
    diariosFiltered,
    setSearchString,
    searchString,
  };
}
