import api from "@api";
import {log} from "@helpers";

const getHistoricoDeAlertasDetalhes = ({
  diario,
  termo,
  dataInicio,
  dataFim,
  pagina = 1,
  setCurrentProgress,
}) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  if (!currentUser) return;
  const payload = {
    pathWithDomain: `${process.env.REACT_APP_API_V2}/historico_alerta/search?diario=${diario}&termo=${termo}&dataInicio=${dataInicio}&dataFim=${dataFim}&pagina=${pagina}&exato=true`,
    method: "get",
  };
  log("Listar histórico de alertas detalhes payload: ", payload);

  return api(payload, setCurrentProgress);
};

export default getHistoricoDeAlertasDetalhes;
