import api from "@api";

import {log} from "@helpers";

const deactivateAlerta = (data, setCurrentProgress) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  if (!currentUser) return;
  const payload = {
    pathWithDomain: `${process.env.REACT_APP_API_V2}/termo/desabilita?clienteId=${currentUser.user.idCliente}`,
    method: "put",
    body: {
      termo: data.termo,
      id: data.id,
    },
  };
  log("Desativar alerta de termo payload:", payload);
  return api(payload, setCurrentProgress);
};

export default deactivateAlerta;
