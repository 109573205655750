/* eslint-disable no-console */
import moment from "moment";
import jwt_decode from "jwt-decode";
import {authenticationService} from "@services/authentication.service";
// import fetchProgress from "fetch-progress";
import {log} from "@helpers";

const base = `${process.env.REACT_APP_API_URL}`;

const api = async (payload, setCurrentProgress) => {
  const {path, method, body, headers} = payload;
  const hasDomainOverwrite = !!payload.pathWithDomain;
  const url = hasDomainOverwrite ? payload.pathWithDomain : `${base}/${path}`;
  const bodyJson = JSON.stringify(body);

  let headersObj = {
    "Content-Type": "application/json",
    ...authHeader(),
    // overwrite if headers are passed in
    ...headers,
  };

  const currentTokenExpiration = moment(expireDate());
  const now = moment();

  if (now.isAfter(currentTokenExpiration)) {
    const res = await makeRefreshTokenRequest();
    const {headers: refreshTokenHeaders} = res;
    const refreshToken = refreshTokenHeaders.get("refresh_token_site");
    const decoded = jwt_decode(refreshToken);

    headersObj = {
      ...headersObj,
      ...authHeader(true, refreshToken),
    };

    const currentUser = authenticationService.currentUserValue;

    const auth = {
      ...currentUser,
      token: {
        accessToken: `Bearer ${refreshToken}`,
        refreshToken: refreshToken,
        expireDate: moment.unix(decoded.exp).toISOString(),
      },
    };

    authenticationService.persistUserAuthLocalStorage(auth);
  }

  try {
    const setProgress =
      setCurrentProgress && typeof setCurrentProgress === "function"
        ? setCurrentProgress
        : null;

    // Since content-length is not yet sent from backend, set mocked progress:
    setProgress && setProgress(100);

    return (
      fetch(url, {method, body: bodyJson, headers: headersObj})
        //@TODO: uncomment below once content-length is sent from backend
        // .then(
        //   fetchProgress({
        //     onProgress(progress) {
        //       //@TODO: add content-length in the response headers backend.
        //       console.log({progress});
        //       // setProgress && setProgress(progress.percentage);
        //     },
        //     onError(err) {
        //       console.log(err);
        //     },
        //   })
        // )
        .then(handleResponse)
    );
  } catch (error) {
    log("Error request: ", error);
    return error;
  }
};

export const handleResponse = (response) => {
  return response.text().then((text) => {
    const data = text && IsJsonString(text) && JSON.parse(text);
    if (!response.ok) {
      if ([401, 403].indexOf(response.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        authenticationService.logout();
        if (!response?.url?.includes("/login")) {
          window.location.reload();
        }
      }

      let error;

      if (data.type) {
        throw Error(data.type);
      }

      if (response.status === 500) {
        error = "Erro inesperado. Tente novamente mais tarde.";
      } else {
        error =
          (data &&
            (data.message ||
              (data.errors?.length && data.errors[0]?.message) ||
              data.msg)) ||
          response.statusText;
      }

      return Promise.reject(error);
    }

    return {data, headers: response.headers};
  });
};

function IsJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function authHeader(overwriteToken = false, token = null) {
  // Return authorization header with jwt token
  const currentUser = authenticationService.currentUserValue;
  if (currentUser && currentUser.token) {
    if (!overwriteToken) return {Authorization: `${currentUser.token.accessToken}`};

    return {Authorization: `Bearer ${token}`};
  } else {
    return {};
  }
}

export function expireDate() {
  const currentUser = authenticationService.currentUserValue;
  if (currentUser && currentUser.token) {
    return currentUser.token.expireDate;
  } else {
    return null;
  }
}

export function makeRefreshTokenRequest() {
  const currentUser = authenticationService.currentUserValue;
  if (!currentUser || !currentUser.token) return;

  const path = "auth/v1/refresh_token";
  const url = `${process.env.REACT_APP_API_V2}/${path}`;
  const bodyJson = JSON.stringify({
    refresh_token: currentUser.token.refreshToken,
  });
  const headersObj = {
    "Content-Type": "application/json",
    ...authHeader(),
  };
  return fetch(url, {method: "POST", body: bodyJson, headers: headersObj}).then(
    handleResponse
  );
}

export default api;
