import api from "@api";

import {log} from "@helpers";

const deleteAlerta = (termoId, setCurrentProgress) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  if (!currentUser) return;
  const payload = {
    pathWithDomain: `${process.env.REACT_APP_API_V2}/termo/${termoId}`,
    method: "delete",
  };
  log("Deletar alerta de termo payload: ", payload);

  return api(payload, setCurrentProgress);
};

export default deleteAlerta;
