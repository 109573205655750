import ga4 from "react-ga4";
import ReactPixel from "react-facebook-pixel";

const TRACKING_ID = process.env.REACT_APP_GOOGLE_TRACKING_ID || "G-FW99Z6FM9C";
const REACT_PIXEL_KEY = process.env.REACT_PIXEL_KEY || "605958223433385";
const isProduction = process.env.NODE_ENV === "production";

// @TODO: GDPR Compliance (accept cookies)

export const init = () => {
  ga4.initialize(TRACKING_ID, {
    testMode: !isProduction,
  });

  if (isProduction) {
    ReactPixel.init(REACT_PIXEL_KEY);
  } else {
    console.log(
      "%cAnalytics and Pixel are not enabled in this environment",
      "background: red; color: #e8e8e8; font-size: 12px"
    );
  }
};

export const sendEvent = (name: string) => {
  ga4.event("screen_view", {
    app_name: "AdooPortal",
    screen_name: name,
  });
};

export const sendPageview = (path: string) => {
  ga4.send({
    hitType: "pageview",
    page: path,
  });
  ReactPixel.pageView();
};

export const actionTrack = (
  action: string,
  nonInteraction?: boolean,
  aditionalData?: any
) => {
  ga4.event({
    category: "AdooPortal",
    action,
    nonInteraction, // optional, true/false
    label: JSON.stringify(aditionalData), // optional
    // value, // optional, must be a number
    // transport: "xhr", // optional, beacon/xhr/image
  });
  ReactPixel.trackCustom(action, aditionalData);
};
