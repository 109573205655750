import {Formik} from "formik";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "@adoo-ui/Atom/Button";
import {useState, useEffect, useCallback} from "react";
import "./EsqueciSenhaForm.scss";
import sendEmailEsqueciSenha from "@api/requests/auth/sendEmailEsqueciSenha";
import checkMark from "@images/check-mark.png";

import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

function EsqueciSenhaForm({onFecharEsqueciMinhaSenha}) {
  const [showEmailEnviadoComSucesso, setShowEmailEnviadoComSucesso] = useState(false);
  const {executeRecaptcha} = useGoogleReCaptcha();
  const [token, setToken] = useState("");

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const result = await executeRecaptcha("homepage");
    setToken(result);
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const enviarMensagemTrocarSenha = async ({email}) => {
    let tokenRecaptcha;
    await handleReCaptchaVerify().then(() => {
      tokenRecaptcha = token;
    });
    await sendEmailEsqueciSenha(email, tokenRecaptcha).then(() => {
      setShowEmailEnviadoComSucesso(true);
    });
  };

  const handleFecharEsqueciMinhaSenha = () => {
    onFecharEsqueciMinhaSenha();
  };

  return (
    <>
      {!showEmailEnviadoComSucesso ? (
        <div>
          <h3 className="mb-3">Redefinição de senha</h3>
          <p className="mb-3">
            Esqueceu a senha? Digite seu email abaixo para iniciar o processo de
            redefinição
          </p>
          <Formik
            initialValues={{email: ""}}
            validate={(values) => {
              const errors = {};

              if (!values.email) {
                errors.email = "Campo Obrigatório";
              } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                errors.email = "Email inválido";
              }

              return errors;
            }}
            onSubmit={async (values, {setSubmitting}) => {
              await enviarMensagemTrocarSenha(values);
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit} className="login-form">
                <Form.Group className="mt-4">
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      placeholder="Endereço de E-mail"
                      aria-describedby="inputGroupPrepend"
                      name="email"
                      isInvalid={!!errors.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.email && touched.email && errors.email}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  className="adoo-btn adoo-btn--lg mt-3 adoo-btn--spaced-md adoo-btn--color-new"
                >
                  Enviar
                </Button>
              </Form>
            )}
          </Formik>
          <div className="mt-4">
            <span onClick={handleFecharEsqueciMinhaSenha} className="btn-voltar">
              Voltar
            </span>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="mb-4">
            <img
              src={checkMark}
              width="50"
              alt="E-mail de recuperação enviado com sucesso"
            />
          </div>
          <div style={{textAlign: "center"}}>
            <h2>E-mail de recuperação enviado com sucesso!</h2>
          </div>
        </div>
      )}
    </>
  );
}

export default EsqueciSenhaForm;
