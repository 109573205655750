import getAllDiarios from "@api/requests/diarios/getAllDiarios";
import React, {createContext, useContext, useEffect, useState} from "react";
import {useDiariosOficiaisFiltro} from "./DiarioOficialFiltro";
import {useLoadingBar} from "./LoadingBar";

const AllDiariosFiltroContext = createContext();
export default function AllDiariosFiltroProvider({children}) {
  const {poderesOptions, esferasOptions, ordenacaoOptions} = useDiariosOficiaisFiltro();

  const filtroInit = {
    poder: [
      poderesOptions[0].key,
      poderesOptions[1].key,
      poderesOptions[2].key,
      poderesOptions[3].key,
    ],
    esfera: [esferasOptions[0].key, esferasOptions[1].key, esferasOptions[2].key],
    ordenacao: ordenacaoOptions[0],
    dataInicio: null,
    dataFim: null,
    dataFocus: null,
  };

  const {setCurrentProgress} = useLoadingBar();
  const [filtro, setFiltro] = useState(filtroInit);
  const [isLoading, setIsLoading] = useState(false);
  const [diarios, setDiarios] = useState([]);
  const [diarioSelecionado, setDiarioSelecionado] = useState({});
  const [searchString, setSearchString] = useState("");
  const [buscaDiarioOficial, setBuscaDiarioOficial] = useState("");
  const [showCountExecutivo, setShowCountExecutivo] = useState(14);
  const [showCountJudiciario, setShowCountJudiciario] = useState(14);
  const [showCountLegislativo, setShowCountLegislativo] = useState(14);
  const [showCountMp, setShowCountMp] = useState(14);
  const [esferaCheckedState, setEsferaCheckedState] = useState(
    new Array(esferasOptions.length).fill(true)
  );
  const [poderCheckedState, setPoderCheckedState] = useState(
    new Array(poderesOptions.length).fill(true)
  );

  useEffect(() => {
    reloadAllDiarios();
  }, []);

  const reloadAllDiarios = async () => {
    setIsLoading(true);
    if (filtro?.poder?.length === 0 && filtro?.esfera?.length === 0) {
      filtro.poder = "";
      filtro.esfera = "";
    }
    if (filtro?.poder?.length === 0 && filtro?.esfera?.length > 0) {
      filtro.poder = "";
      filtro.esfera = "";
    }

    const {data: diarios} = await getAllDiarios(filtro, setCurrentProgress);
    setDiarios(diarios || []);
    setIsLoading(false);
  };

  const selecionaPoderEsfera = (poder, esfera) => {
    setFiltro({...filtro, poder: poder, esfera: esfera});
  };

  const selecionarDiario = (diario) => {
    setDiarioSelecionado(diario);
  };

  const selecionaDataPeriodo = (dataInicio, dataFim) => {
    setTimeout(() => {
      setFiltro({
        ...filtro,
        dataInicio: dataInicio?.toISOString(),
        dataFim: dataFim?.toISOString(),
      });
    }, 200);
  };

  const selecionaDataFocus = (dataFocus) => {
    setFiltro({...filtro, dataFocus});
  };

  const limparFiltro = () => {
    setFiltro(filtroInit);
  };

  const updateEsferaCheckedState = (position) => {
    const updatedEsferaCheckedState = esferaCheckedState.map((item, index) =>
      index === position ? !item : item
    );
    setEsferaCheckedState(updatedEsferaCheckedState);
  };

  const updatePoderCheckedState = (position) => {
    const updatedPoderCheckedState = poderCheckedState.map((item, index) =>
      index === position ? !item : item
    );
    setPoderCheckedState(updatedPoderCheckedState);
  };

  const handleGetChips = () => {
    const poderesSelecionados = filtro.poder;
    const poderesSelecionadosValues = poderesOptions.filter((p) =>
      poderesSelecionados.includes(p.key)
    );

    const poderesSelecionadosList = poderesSelecionadosValues.map((p) => {
      return {id: p.key, name: p.label};
    });

    const esferasSelecionadas = filtro.esfera;
    const esferasSelecionadasValues = esferasOptions.filter((p) =>
      esferasSelecionadas.includes(p.key)
    );

    const esferasSelecionadasList = esferasSelecionadasValues.map((p) => {
      return {id: p.key, name: p.label};
    });

    return (
      <React.Fragment>
        <div className="chips">
          {poderesSelecionadosList.length === 4 ? (
            <span>Todos os poderes</span>
          ) : (
            poderesSelecionadosList.map((poder) => <span>{poder.name}</span>)
          )}
          {esferasSelecionadasList.length === 3 ? (
            <span>Todas as esferas</span>
          ) : (
            esferasSelecionadasList.map((esfera) => <span>{esfera.name}</span>)
          )}
        </div>
      </React.Fragment>
    );
  };

  const handleApplyFilter = () => {
    const esferasDiarios = esferasOptions
      .filter((option, index) => esferaCheckedState[index])
      .map((option) => option.key);

    const poderesDiarios = poderesOptions
      .filter((option, index) => poderCheckedState[index])
      .map((option) => option.key);

    selecionaPoderEsfera(poderesDiarios, esferasDiarios);
    setSearchString(buscaDiarioOficial);
  };

  const handleEnterKeyPress = (event) => {
    setBuscaDiarioOficial(event.target.value);
    if (event.key === "Enter" && verifyEsferaCheckedState() === false) {
      setSearchString(buscaDiarioOficial);
      handleApplyFilter();
    }
  };

  const verifyEsferaCheckedState = () => {
    let result = false;
    let esferaSize = 0;
    esferaCheckedState.forEach((item) => {
      if (item === false) {
        esferaSize++;
      }
    });
    if (esferaSize === 3) {
      result = true;
    }

    return result;
  };

  const verifyPoderCheckedState = () => {
    let result = false;
    let poderSize = 0;
    poderCheckedState.forEach((item) => {
      if (item === false) {
        poderSize++;
      }
    });
    if (poderSize === 4) {
      result = true;
    }

    return result;
  };

  const handleClearFiltro = () => {
    setSearchString("");
    limparFiltro();
    setEsferaCheckedState(new Array(esferasOptions.length).fill(true));
    setPoderCheckedState(new Array(poderesOptions.length).fill(true));
  };

  const handleShowMore = (poder) => {
    switch (poder) {
      case "executivo":
        setShowCountExecutivo(showCountExecutivo + 14);
        break;
      case "legislativo":
        setShowCountLegislativo(showCountLegislativo + 14);
        break;
      case "judiciario":
        setShowCountJudiciario(showCountJudiciario + 14);
        break;
      case "mp":
        setShowCountMp(showCountMp + 14);
        break;
      default:
    }
  };

  const handleShowLess = (poder) => {
    switch (poder) {
      case "executivo":
        if (showCountExecutivo === 14) {
          return;
        }
        setShowCountExecutivo(showCountExecutivo - 14);
        break;
      case "legislativo":
        if (showCountLegislativo === 14) {
          return;
        }
        setShowCountLegislativo(showCountLegislativo - 14);
        break;
      case "judiciario":
        if (showCountJudiciario === 14) {
          return;
        }
        setShowCountJudiciario(showCountJudiciario - 14);
        break;
      case "mp":
        if (showCountMp === 14) {
          return;
        }
        setShowCountMp(showCountMp - 14);
        break;
      default:
    }
  };

  return (
    <AllDiariosFiltroContext.Provider
      value={{
        selecionaPoderEsfera,
        filtro,
        esferasOptions,
        ordenacaoOptions,
        poderesOptions,
        reloadAllDiarios,
        diarios,
        isLoading,
        selecionarDiario,
        diarioSelecionado,
        selecionaDataPeriodo,
        selecionaDataFocus,
        limparFiltro,
        updateEsferaCheckedState,
        updatePoderCheckedState,
        esferaCheckedState,
        setEsferaCheckedState,
        poderCheckedState,
        setPoderCheckedState,
        handleGetChips,
        handleApplyFilter,
        handleEnterKeyPress,
        verifyEsferaCheckedState,
        verifyPoderCheckedState,
        handleClearFiltro,
        searchString,
        setSearchString,
        handleShowMore,
        showCountExecutivo,
        showCountJudiciario,
        showCountLegislativo,
        showCountMp,
        handleShowLess,
      }}
    >
      {children}
    </AllDiariosFiltroContext.Provider>
  );
}

export function useAllDiariosFiltro() {
  const context = useContext(AllDiariosFiltroContext);
  const {
    selecionaPoderEsfera,
    filtro,
    esferasOptions,
    ordenacaoOptions,
    poderesOptions,
    reloadAllDiarios,
    diarios,
    isLoading,
    selecionarDiario,
    diarioSelecionado,
    selecionaDataPeriodo,
    selecionaDataFocus,
    limparFiltro,
    updateEsferaCheckedState,
    updatePoderCheckedState,
    esferaCheckedState,
    setEsferaCheckedState,
    poderCheckedState,
    setPoderCheckedState,
    handleGetChips,
    handleApplyFilter,
    handleEnterKeyPress,
    verifyEsferaCheckedState,
    verifyPoderCheckedState,
    handleClearFiltro,
    searchString,
    setSearchString,
    handleShowMore,
    showCountExecutivo,
    showCountJudiciario,
    showCountLegislativo,
    showCountMp,
    handleShowLess,
  } = context;
  return {
    selecionaPoderEsfera,
    filtro,
    esferasOptions,
    ordenacaoOptions,
    poderesOptions,
    reloadAllDiarios,
    diarios,
    isLoading,
    selecionarDiario,
    diarioSelecionado,
    selecionaDataPeriodo,
    selecionaDataFocus,
    limparFiltro,
    updateEsferaCheckedState,
    updatePoderCheckedState,
    esferaCheckedState,
    setEsferaCheckedState,
    poderCheckedState,
    setPoderCheckedState,
    handleGetChips,
    handleApplyFilter,
    handleEnterKeyPress,
    verifyEsferaCheckedState,
    verifyPoderCheckedState,
    handleClearFiltro,
    searchString,
    setSearchString,
    handleShowMore,
    showCountExecutivo,
    showCountJudiciario,
    showCountLegislativo,
    showCountMp,
    handleShowLess,
  };
}
