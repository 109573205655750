import api from "@api";

import {log} from "@helpers";
import {AtivarAlertaAPIInput, AtivarAlertaAPIOutput} from "@contracts";

const activateAlerta = (
  data: AtivarAlertaAPIInput,
  setCurrentProgress?: Function
): Promise<AtivarAlertaAPIOutput | void> | boolean => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser") || "");
  if (!currentUser) return false;
  const payload = {
    pathWithDomain: `${process.env.REACT_APP_API_V2}/termo/habilita?clienteId=${currentUser.user.idCliente}`,
    method: "put",
    body: {
      termo: data.termo,
      id: data.id,
    },
  };
  log("Ativar alerta de termo payload:", payload);

  return api(payload, setCurrentProgress);
};

export default activateAlerta;
