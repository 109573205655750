import React from "react";
import Routes from "../../Routes";

const Root = (props) => {
  const {auth, user, userLoading} = props;
  const childProps = {auth, user, userLoading};

  return (
    <div className="application">
      <Routes childProps={childProps} />
    </div>
  );
};

export default Root;
