import api from "@api";
import {log} from "@helpers";

import {CartoesSalvosAPI, CartoesSalvosPaymentMethodsAPI} from "@contracts";
import {CartaoSalvoType} from "@types";

const getCartoesSalvos = async (): Promise<CartaoSalvoType[] | void> => {
  const currentUser: string = JSON.parse(localStorage.getItem("currentUser") || "");
  if (!currentUser) return;
  const url = `${process.env.REACT_APP_API_V2}/api/v1/forma_pagamento/cliente`;

  const payload = {
    pathWithDomain: `${url}`,
    method: "get",
  };

  log("payload", payload);

  try {
    const {data}: CartoesSalvosAPI = (await api(payload)) ?? {data: []};
    log("data cartões salvos", data);

    const cartoes: CartaoSalvoType[] = [];

    if (data && data.paymentMethods) {
      data.paymentMethods.forEach((cartao: CartoesSalvosPaymentMethodsAPI, i: number) => {
        const cartaoMasked = cartao.data.displayNumber;
        const digits = cartaoMasked.split("-");
        const lastDigits = digits && digits[3] ? digits[3] : "???";
        const cartaoExiste = cartoes.find((c) => c.final === lastDigits);
        if (!cartaoExiste) {
          cartoes.push({
            id: cartao.id,
            identificador: `${cartao.item_type === "credit_card" ? "(Crédito)" : ""} ${
              cartao.data.brand
            }`,
            nome: cartao.data.holderName,
            final: lastDigits,
            validade: `${
              cartao.data.month < 10 ? `0${cartao.data.month}` : cartao.data.month
            }/${cartao.data.year}`,
            isDefault: cartao.id === data.formaPagamentoDefault,
          });
        }
      });
    }

    log("cartoes salvos lista", cartoes);

    return cartoes;
  } catch (error) {
    throw new Error(error as string);
  }
};

export default getCartoesSalvos;
