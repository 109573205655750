import api from "@api";

import {log} from "@helpers";

const getAlertas = (setCurrentProgress) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  if (!currentUser) return;
  const payload = {
    pathWithDomain: `${process.env.REACT_APP_API_V2}/v2/clientes/retornaDadosUsuarioAposLogin`,
    method: "put",
    body: {
      email: currentUser.user.email,
      nome: "",
      token: currentUser.token.accessToken.replace("Bearer ", ""),
    },
  };
  log("Listar alertas de termo payload: ", payload);

  return api(payload, setCurrentProgress);
};

export default getAlertas;
