import React from "react";
import beneficio1 from "@images/login/beneficios-navegacao-diarios.png";
import beneficio2 from "@images/login/beneficios-cadastro-alertas.png";
import beneficio3 from "@images/login/beneficios-buscar-termos.png";
import Carousel from "react-bootstrap/Carousel";

function LoginModalSidePanel({ordemItens = ["navegacao", "buscar-termo", "alertas"]}) {
  const itemNavegacao = () => {
    return (
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={beneficio1}
          alt="Navegação nos Diários Oficiais"
        />
        <Carousel.Caption style={{width: "89%", marginLeft: "-9%"}}>
          <h3>Chega de pesquisas cansativas!</h3>
          <p>
            Ache o diário oficial que está procurando através da nossa{" "}
            <strong>navegação</strong>.<br />
            Somos a mais completa plataforma de Diários Oficiais do Brasil!
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    );
  };

  const itemBuscarTermo = () => {
    return (
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={beneficio3}
          alt="Buscar termo nos diários oficiais"
        />
        <Carousel.Caption style={{width: "89%", marginLeft: "-9%"}}>
          <h3>Busque termos específicos</h3>
          <p>
            Com a nossa <strong>Busca de Termos</strong>, é possível você procurar
            qualquer termo em quaisquer jornais ou Diários Oficiais disponíveis na nossa
            plataforma de forma muito mais simples e intuitiva.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    );
  };

  const itemAlertas = () => {
    return (
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={beneficio2}
          alt="Alerta e notificações nas publicações dos diários oficiais"
        />
        <Carousel.Caption style={{width: "89%", marginLeft: "-9%"}}>
          <h3>Crie alertas e receba notificações</h3>
          <p>
            Através do <strong>Cadastro de Alertas</strong>, digite o termo desejado para
            que o Adoo monitore quando este termo for disponibilizado no Diário Oficial.
            Seja notificado(a) por e-mail e não perca nenhuma publicação.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    );
  };

  const itemsToRender = (slot = 0) => {
    switch (ordemItens[slot]) {
      case "navegacao":
        return itemNavegacao();
      case "buscar-termo":
        return itemBuscarTermo();
      case "alertas":
        return itemAlertas();
      default:
        return itemNavegacao();
    }
  };

  return (
    <Carousel variant="dark">
      {itemsToRender(0)}
      {itemsToRender(1)}
      {itemsToRender(2)}
    </Carousel>
  );
}

export default LoginModalSidePanel;
