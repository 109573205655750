import api from "@api";

import {log} from "@helpers";
import {IsPagamentoAprovadoTypeAPI} from "@contracts";

const getIsPagamentoAprovado = async (
  setCurrentProgress: undefined
): Promise<IsPagamentoAprovadoTypeAPI | void> => {
  const currentUser: string = JSON.parse(localStorage.getItem("currentUser") || "");
  if (!currentUser) return;
  const payload = {
    pathWithDomain: `${process.env.REACT_APP_API_V2}/api/v1/forma_pagamento/is_payment_approved`,
    method: "GET",
  };
  log("GET getIsPagamentoAprovado:", payload);

  // @TODO: remove
  // return mockAPIRequest({
  //   timeToWait: 1000,
  //   whatToReturn: {data: {status: "pending"}},
  //   shouldThrowError: false,
  // });
  return api(payload, setCurrentProgress);
};

export default getIsPagamentoAprovado;
