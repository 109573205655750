import api from "@api";

type Props = {
  token: string;
};

const verificarConta = ({token}: Props) => {
  const payload = {
    pathWithDomain: `${process.env.REACT_APP_API_V2}/v3/clientes/is_valid_token?token=${token}`,
    method: "GET",
  };

  return api(payload);
};

export default verificarConta;
