import {initializeApp} from "firebase/app";
import {getAnalytics, isSupported} from "firebase/analytics";
import {getAuth} from "firebase/auth";
import {log} from "@helpers";

const firebaseConfig = {
  apiKey: process.env.REACT_FIREBASE_API_KEY || "AIzaSyDZcDCFa5B0YfdnmJ-sXKLPT5af0NkYwC4",
  authDomain: process.env.REACT_FIREBASE_AUTH_DOMAIN || "portal-adoo.firebaseapp.com",
  projectId: process.env.REACT_FIREBASE_PROJECT_ID || "portal-adoo",
  storageBucket: process.env.REACT_FIREBASE_STORAGE_BUCKET || "portal-adoo.appspot.com",
  messagingSenderId: process.env.REACT_FIREBASE_MESSAGING_SENDER_ID || "1096482392288",
  appId:
    process.env.REACT_FIREBASE_APP_ID || "1:1096482392288:web:fb9264464a2a6d6c66e885",
  measurementId: process.env.REACT_FIREBASE_MEASUREMENT_ID || "G-W448WBHP2Y",
};

let auth;
isSupported().then((result) => {
  log("Environment supports firebase auth: ", result);
  if (result) {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    // @TODO: check if getAnalytics() here can be removed
    const analytics = getAnalytics(app);
    auth = getAuth();
  }
});

export default auth;
