const SEARCH = "/busca";
const SEARCH_RESULTS = "/busca/resultados";
const SEARCH_RESULTS_DIARIOS = "/busca/selecionar-diario";
const SEARCH_RESULTS_DATA = "/busca/selecionar-data";
const VIEWER = "/conteudo-viewer";
const ALERTAS = "/cadastro-alertas";
const ALERTAS_RESULTADOS = "/cadastro-alertas/resultados";
const ALERTAS_RESULTADOS_HISTORICO = "/cadastro-alertas/historico";
const NAVEGACAO = "/navegacao";
const NAVEGACAO_PODER_ESFERA = "/navegacao/:poder/:esfera";
const NAVEGACAO_DETALHES = "/navegacao/:id/:poder/:esfera/:diarioSlug";
const GERAL_EDITAR_PERFIL = "/geral/editar-perfil";
const GERAL_ALTERAR_SENHA = "/geral/alterar-senha";
const GERAL_EMAIL_SMS = "/geral/email-e-sms";
const PLANOS_PRINCIPAL = "/planos/inicio";
const PLANOS_PAGAMENTOS = "/planos/pagamentos";
const SUPORTE_RELATAR_ERRO = "/suporte/relatar-erro";
const SUPORTE_AJUDA = "/suporte/ajuda";
const SUPORTE_ALERTA_MONITORAMENTO = "/suporte/ajuda/alerta-de-monitoramento";
const SUPORTE_TERMOS_PROCESSOS = "/suporte/ajuda/termos-e-processos";
// const SUPORTE_ACOMPANHAR_LICITACAO = "/suporte/ajuda/acompanhar-licitacao";
const SUPORTE_ASSINATURAS = "/suporte/ajuda/assinaturas";
const SUPORTE_METODOS_DE_PAGAMENTO = "/suporte/ajuda/metodos-de-pagamento";
const SUPORTE_NAVEGACAO = "/suporte/ajuda/navegacao";
const SUPORTE_GERAL = "/suporte/ajuda/geral";
const SUPORTE_SUPORTE = "/suporte/ajuda/suporte";
const SUPORTE_PAGAMENTOS = "/suporte/ajuda/pagamentos";
const TERMOS_USO = "/termos-de-uso";
const POLITICA_PRIVACIDADE = "/politica-privacidade";
const RECOVERY_PASSWORD = "/recovery-password";
const HOME = "/";
const CONSULTA_PROCESSUAL = "/consulta-processual";
const CONSULTA_PROCESSUAL_RESULTADOS = "/consulta-processual/resultados";
const VERIFICAR_CONTA = "/verificar-conta";
const INDIQUE_E_GANHE = "/planos/indique-e-ganhe";

const routeCodes = {
  HOME,
  SEARCH,
  SEARCH_RESULTS,
  SEARCH_RESULTS_DIARIOS,
  SEARCH_RESULTS_DATA,
  VIEWER,
  NAVEGACAO,
  NAVEGACAO_PODER_ESFERA,
  NAVEGACAO_DETALHES,
  ALERTAS,
  ALERTAS_RESULTADOS,
  ALERTAS_RESULTADOS_HISTORICO,
  GERAL_EDITAR_PERFIL,
  GERAL_ALTERAR_SENHA,
  GERAL_EMAIL_SMS,
  PLANOS_PRINCIPAL,
  PLANOS_PAGAMENTOS,
  SUPORTE_RELATAR_ERRO,
  SUPORTE_AJUDA,
  TERMOS_USO,
  POLITICA_PRIVACIDADE,
  RECOVERY_PASSWORD,
  CONSULTA_PROCESSUAL,
  CONSULTA_PROCESSUAL_RESULTADOS,
  SUPORTE_ALERTA_MONITORAMENTO,
  SUPORTE_TERMOS_PROCESSOS,
  // SUPORTE_ACOMPANHAR_LICITACAO,
  SUPORTE_ASSINATURAS,
  SUPORTE_METODOS_DE_PAGAMENTO,
  SUPORTE_NAVEGACAO,
  SUPORTE_GERAL,
  SUPORTE_SUPORTE,
  VERIFICAR_CONTA,
  SUPORTE_PAGAMENTOS,
  INDIQUE_E_GANHE,
};

export {routeCodes};
