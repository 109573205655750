import api from "@api";

import {log} from "@helpers";

const setCartaoDefault = async (data, setCurrentProgress) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  if (!currentUser) return;
  const payload = {
    pathWithDomain: `${process.env.REACT_APP_API_V2}/api/v1/forma_pagamento/update-payment-default`,
    method: "PUT",
    body: {
      default_payment_method_id: data.id,
    },
  };
  log("PUT setCartaoDefault:", payload);

  return api(payload, setCurrentProgress);
};

export default setCartaoDefault;
