import React, {createContext, useState, useContext} from "react";

const DiarioOficialFiltroContext = createContext();

export default function DiarioOficialFiltroProvider({children}) {
  const poderesOptions = [
    {
      key: "executivo",
      label: "Executivo",
    },
    {
      key: "legislativo",
      label: "Legislativo",
    },
    {
      key: "judiciario",
      label: "Judiciário",
    },
    {
      key: "mp",
      label: "Ministério Público",
    },
  ];

  const esferasOptions = [
    {
      key: "federal",
      label: "Federal",
    },
    {
      key: "estadual",
      label: "Estadual",
    },
    {
      key: "municipal",
      label: "Municipal",
    },
  ];

  const ordenacaoOptions = [
    {
      label: "Mais Recentes primeiro",
      key: "data",
      value: "desc",
    },
    {
      label: "Mais Antigos primeiro",
      key: "data",
      value: "asc",
    },
    {
      label: "Mais acessados",
      key: "views",
      value: "desc",
    },
  ];

  const filtroInit = {
    poder: poderesOptions[0].key,
    esfera: [esferasOptions[0].key, esferasOptions[1].key, esferasOptions[2].key],
    ordenacao: ordenacaoOptions[0],
    dataInicio: null,
    dataFim: null,
    dataFocus: null,
  };

  const [filtro, setFiltro] = useState(filtroInit);
  const [diarioSelecionado, setDiarioSelecionado] = useState({});

  const limparFiltro = () => {
    setFiltro(filtroInit);
  };

  const selecionarPoder = (value) => {
    setFiltro({...filtro, poder: value});
  };

  const selecionarOrdenacao = (value) => {
    setFiltro({...filtro, ordenacao: value});
  };

  const selecionarDiario = (diario) => {
    setDiarioSelecionado(diario);
  };

  const selecionarEsfera = (value) => {
    setFiltro({...filtro, esfera: [value]});
  };

  const selecionaPoderEsfera = (poder, esfera) => {
    setFiltro({...filtro, poder: poder, esfera: esfera});
  };

  const sobrescreverEsferasSelecionadas = (esferasKeys) => {
    setFiltro({...filtro, esfera: esferasKeys});
  };

  const getChips = () => {
    const poderSelecionado = filtro.poder;
    const poderSelecionadoLabel = poderesOptions.find((p) => p.key === poderSelecionado);

    const esferasSelecionadas = filtro.esfera;
    const esferasSelecionadasValues = esferasOptions.filter((p) =>
      esferasSelecionadas.includes(p.key)
    );

    const esferasSelecionadasList = esferasSelecionadasValues.map((p) => {
      return {id: p.key, name: p.label};
    });

    return [
      {
        id: poderSelecionado,
        name: `${poderSelecionado !== "mp" ? "Poder" : ""} ${
          poderSelecionadoLabel.label
        }`,
      },
      ...esferasSelecionadasList,
    ];
  };

  const removeChip = (value) => {
    const isPoder = poderesOptions.find((p) => p.key === value);
    const isEsfera = esferasOptions.find((p) => p.key === value);

    if (isEsfera) {
      selecionarEsfera(value);
    } else if (isPoder) {
      // Sempre reseta poder executivo por ele ser um radio button
      selecionarPoder(poderesOptions[0].key);
    }
  };

  const limpaChips = () => {
    // Reseta poder para Executivo e Esfera Federal
    setFiltro({...filtro, poder: poderesOptions[0].key, esfera: []});
  };

  const selecionaDataPeriodo = (dataInicio, dataFim) => {
    setTimeout(() => {
      setFiltro({
        ...filtro,
        dataInicio: dataInicio?.toISOString(),
        dataFim: dataFim?.toISOString(),
      });
    }, 200);
  };
  const selecionaDataFocus = (dataFocus) => {
    setFiltro({...filtro, dataFocus});
  };

  return (
    <DiarioOficialFiltroContext.Provider
      value={{
        filtro,
        setFiltro,
        limparFiltro,
        selecionarPoder,
        poderesOptions,
        selecionarEsfera,
        sobrescreverEsferasSelecionadas,
        esferasOptions,
        getChips,
        removeChip,
        limpaChips,
        ordenacaoOptions,
        selecionarOrdenacao,
        selecionarDiario,
        diarioSelecionado,
        selecionaDataPeriodo,
        selecionaDataFocus,
        selecionaPoderEsfera,
      }}
    >
      {children}
    </DiarioOficialFiltroContext.Provider>
  );
}

export function useDiariosOficiaisFiltro() {
  const context = useContext(DiarioOficialFiltroContext);
  const {
    filtro,
    setFiltro,
    limparFiltro,
    selecionarPoder,
    poderesOptions,
    selecionarEsfera,
    sobrescreverEsferasSelecionadas,
    esferasOptions,
    getChips,
    removeChip,
    limpaChips,
    ordenacaoOptions,
    selecionarOrdenacao,
    selecionarDiario,
    diarioSelecionado,
    selecionaDataPeriodo,
    selecionaDataFocus,
    selecionaPoderEsfera,
  } = context;

  return {
    filtro,
    setFiltro,
    limparFiltro,
    selecionarPoder,
    poderesOptions,
    selecionarEsfera,
    sobrescreverEsferasSelecionadas,
    esferasOptions,
    getChips,
    removeChip,
    limpaChips,
    ordenacaoOptions,
    selecionarOrdenacao,
    selecionarDiario,
    diarioSelecionado,
    selecionaDataPeriodo,
    selecionaDataFocus,
    selecionaPoderEsfera,
  };
}
